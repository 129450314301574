import "./SearchBar.css"
import React, {useState, useContext, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import axios from "axios"

import {FilterContext} from "./FilterContext"

import DealTypeDrop from './DealTypeDrop/DealTypeDrop'
import LocationDrop from './LocationDrop/LocationDrop'
import AreaDrop from './AreaDrop/AreaDrop'
import PriceDrop from './PriceDrop/PriceDrop'

const SearchBar = ({onSearch}) => {



  const {
    selectedDeal,
    setSelectedDeal,
    priceRange,
    setPriceRange,
    areaRange,
    setAreaRange,
    location,
    setLocation,
  } = useContext(FilterContext);

  const [finalData, setFinalData] = useState(null); // State to store the final data

  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState('');

  const loc = useLocation()


  const handleInputChange = (e) => {
    const input = e.target.value;

    // Use a regular expression to allow only numbers and optionally a decimal point
    const validInput = input.replace(/[^0-9.]/g, '');

    setInputValue(validInput);
  };


  const findSpecHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }
  
    const allValuesNull =
      selectedDeal.selectedDealtypeIds.length === 0 &&
      selectedDeal.selectedTypeIds.length === 0 &&
      priceRange.startPrice === '' &&
      priceRange.endPrice === '' &&
      areaRange.startArea === '' &&
      areaRange.endArea === '' &&
      areaRange.rooms.length === 0 &&
      location.selectedCityIds.length === 0 &&
      location.selectedDistrictIds.length === 0 &&
      location.selectedUrbanIds.length === 0;
  
    if (allValuesNull) {
      try {
        const response = await axios.get("https://tbilisirealtor-api.onrender.com/api/property/getall");
        const finalData = response.data;
        setFinalData(finalData);
        onSearch(finalData);
        navigate('/products');
      } catch (error) {
        console.error(error);
      }
    } else {
      const {
        selectedDealtypeIds,
        selectedTypeIds
      } = selectedDeal;
  
      const {
        startPrice,
        endPrice
      } = priceRange;
  
      const {
        startArea,
        endArea,
        rooms
      } = areaRange;
  
      const selectedCityIds = location.selectedCityIds.filter(id => id !== '');
      const selectedUrbanIds = location.selectedUrbanIds.filter(id => id !== '');
      const selectedDistrictIds = location.selectedDistrictIds.filter(id => id !== '');
  
      const params = {};
  
      if (selectedCityIds.length > 0) {
        params['city.id'] = selectedCityIds;
      }
      if (selectedDistrictIds.length > 0) {
        params['district.id'] = selectedDistrictIds;
      }
      if (selectedUrbanIds.length > 0) {
        params['urban.id'] = selectedUrbanIds;
      }
      if (selectedDealtypeIds.length > 0) {
        params['dealType.id'] = selectedDealtypeIds;
      }
      if (selectedTypeIds.length > 0) {
        params['type.id'] = selectedTypeIds;
      }
      if (startPrice !== '') {
        params['startPrice'] = startPrice;
      }
      if (endPrice !== '') {
        params['endPrice'] = endPrice;
      }
      if (startArea !== '') {
        params['startArea'] = startArea;
      }
      if (endArea !== '') {
        params['endArea'] = endArea;
      }
      if (rooms.length > 0) {
        params['rooms'] = rooms;
      }
  
      Object.keys(params).forEach(key => {
        if (Array.isArray(params[key])) {
          params[key] = params[key].join(',');
        }
      });
  
      try {
        const response = await axios.get("https://tbilisirealtor-api.onrender.com/api/property/find", { params });
        const finalData = response.data;
        setFinalData(finalData);
        onSearch(finalData);
      } catch (error) {
        console.error(error);
      }
  
      navigate('/products');
    }
  };





  const findWithIdHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if(inputValue){
      try {
        const response = await axios.get(`https://tbilisirealtor-api.onrender.com/api/property/find/${inputValue}`);
        // Handle the response data
        const finalData = response.data;
        setFinalData(finalData);
        onSearch(finalData);
        navigate('/products')

      } catch (error) {
        // Handle the error
        console.error(error);
      }
    }
  }


  useEffect(() => {
    const isLandingPage = loc.pathname === '/';
    
    
    if (!isLandingPage) {
      findSpecHandler();
    }
  }, []);



  

    return(    
      <div className="fpanel-wrapper">
        <div className="fpanel-container">

          <div className="searchbar-wrapper">

            <DealTypeDrop 
                selectedDeal={selectedDeal}
                setSelectedDeal={setSelectedDeal}
            />

            <LocationDrop 
                location={location}
                setLocation={setLocation}
            />

            <AreaDrop
                areaRange={areaRange}
                setAreaRange={setAreaRange} 
            />

            <PriceDrop
                priceRange={priceRange}
                setPriceRange={setPriceRange}
            />

            <input 
              type="text" 
              className="search-input" 
              placeholder="ID, ტელ." 
              value= {inputValue}
              onChange={handleInputChange}
            />
            <button className="searchbutton" onClick={inputValue? findWithIdHandler :findSpecHandler}>ძებნა</button>
          </div>

          <div className="mobile-searchbar-wrapper">

            <div className="mobile-searchbar-upper">

              <DealTypeDrop 
                  selectedDeal={selectedDeal}
                  setSelectedDeal={setSelectedDeal}
              />

            </div>

            <div className="mobile-searchbar-mid-first">

              <LocationDrop 
                  location={location}
                  setLocation={setLocation}
              />
              

            </div>

            <div className="mobile-searchbar-mid-second">

              <div className="left">
                <AreaDrop
                    areaRange={areaRange}
                    setAreaRange={setAreaRange} 
                />
              </div>
              <div className="right">
                <PriceDrop
                    priceRange={priceRange}
                    setPriceRange={setPriceRange}
                />
              </div>
            </div>

            <div className="mobile-searchbar-lower">

              <div className="left">
                <input 
                  type="text" 
                  className="search-input" 
                  placeholder="ID, ტელ." 
                  value= {inputValue}
                  onChange={handleInputChange}
                />
              </div>

              <div className="right">
                <button className="searchbutton" onClick={inputValue? findWithIdHandler :findSpecHandler}>ძებნა</button>
              </div>
              
          
            </div>
              
          </div>


      </div>
    </div>
    )
}

export default SearchBar