import React, { useState } from 'react';
import axios from 'axios';
import './PopularStatements.css';
import Cookies from 'js-cookie';


const PopularStatements = () => {

  const [id, setId] = useState('');
  const [message, setMessage] = useState('');

  const token = Cookies.get('token');

  const handleAddId = () => {
    axios
      .post(
        `https://tbilisirealtor-api.onrender.com/api/property/add-id?id=${id}`,
        null, // request data (null in this case)
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the successful response, e.g., display a success message.
        setMessage('ID added successfully');
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors, e.g., display an error message.
        setMessage('Error adding ID');
        console.error(error);
      });
  };


  const handleRemoveAllIds = () => {
    axios
      .delete('https://tbilisirealtor-api.onrender.com/api/property/delete-all-ids', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the successful response, e.g., display a success message.
        setMessage('All IDs removed successfully');
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors, e.g., display an error message.
        setMessage('Error removing all IDs');
        console.error(error);
      });
  };


  return (
    <div className="popular-statements-wrapper">
      <h1>დაამატე პოპულარული განცხადებები</h1>


      <input
        type="text"
        placeholder="ID"
        value={id}
        onChange={(e) => setId(e.target.value)}
      />


      <button className="popular-add-btn" onClick={handleAddId}>
        დამატება
      </button>
      <button className="popular-add-btn" onClick={handleRemoveAllIds}>
        წაშლა
      </button>

      <p>{message}</p>
    </div>
  )
}

export default PopularStatements