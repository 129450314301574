import React, {useState, useEffect} from 'react'

import "./LeftPanel.css"

import Door from "../../../assets/miniLogos/Door.png"
import BathTub from "../../../assets/miniLogos/Bath Tub.png"
import Bed from "../../../assets/miniLogos/Bed.png"
import AreaLogo from "../../../assets/miniLogos/Acreage.png"
import Garden from "../../../assets/miniLogos/Garden.png"


import Architecture from "../../../assets/miniLogos/Architecture.png"
import Construction from "../../../assets/miniLogos/Construction.png"
import Design from "../../../assets/miniLogos/Design.png"
import Stairs from "../../../assets/miniLogos/Stairs.png"

import Elevator from "../../../assets/miniLogos/Elevator.png"
import Tv from "../../../assets/miniLogos/Tv.png"
import Gym from "../../../assets/miniLogos/Gym.png"


import Balcony from "../../../assets/miniLogos/Balcony.png"
import FireLogo from "../../../assets/miniLogos/Fire.png"
import Garage from "../../../assets/miniLogos/Garage.png"
import Gas from "../../../assets/miniLogos/Gas.png"
import AC from "../../../assets/miniLogos/Snow.png"
import HotWater from "../../../assets/miniLogos/Water.png"
import Internet from "../../../assets/miniLogos/Wifi.png"
import Furniture from "../../../assets/miniLogos/Furniture.png"

import Electricity from "../../../assets/miniLogos/Electricity.png"
import Road from "../../../assets/miniLogos/Road.png"
import Canalisation from "../../../assets/miniLogos/Canalisation.png"




//Dark Mode Logos 




import DoorD from "../../../assets/miniLogosDark/Door.png"
import BathTubD from "../../../assets/miniLogosDark/Bath Tub.png"
import BedD from "../../../assets/miniLogosDark/Bed.png"
import AreaLogoD from "../../../assets/miniLogosDark/Acreage.png"
import GardenD from "../../../assets/miniLogosDark/Garden.png"


import ArchitectureD from "../../../assets/miniLogosDark/Architecture.png"
import ConstructionD from "../../../assets/miniLogosDark/Construction.png"
import DesignD from "../../../assets/miniLogosDark/Design.png"
import StairsD from "../../../assets/miniLogosDark/Stairs.png"

import ElevatorD from "../../../assets/miniLogosDark/Elevator.png"
import TvD from "../../../assets/miniLogosDark/Tv.png"
import GymD from "../../../assets/miniLogosDark/Gym.png"


import BalconyD from "../../../assets/miniLogosDark/Balcony.png"
import FireLogoD from "../../../assets/miniLogosDark/Fire.png"
import GarageD from "../../../assets/miniLogosDark/Garage.png"
import GasD from "../../../assets/miniLogosDark/Gas.png"
import ACD from "../../../assets/miniLogosDark/Snow.png"
import HotWaterD from "../../../assets/miniLogosDark/Water.png"
import InternetD from "../../../assets/miniLogosDark/Wifi.png"
import FurnitureD from "../../../assets/miniLogosDark/Furniture.png"

import ElectricityD from "../../../assets/miniLogosDark/Electricity.png"
import RoadD from "../../../assets/miniLogosDark/Road.png"
import CanalisationD from "../../../assets/miniLogosDark/Canalisation.png"


//-------------------------------

import Houseview from "./SpecificViews/Houseview"
import Flatview from "./SpecificViews/Flatview"
import Officeview from "./SpecificViews/Officeview"
import Landview from "./SpecificViews/Landview"


const LeftPanel = ({props, theme}) => {


    const [expanded, setExpanded] = useState(false);
    const [showButton, setShowButton] = useState(false);

    //common values
    const type = props.type.display_name
    const dealtype = props.dealType.display_name
    const city = props.city.display_name
    const district = props.district.display_name
    const spcAddress = props.exLocation + ', ' + district + ', ' + city
    
    const price = Number(props.price).toLocaleString()
    const area = props.area
    const description = props.description
    
    const amenities = props.amenities


    // Common just not in Land

    const buildingCondition = props.buildingCondition
    const rooms = props.rooms
    const bedrooms = props.bedrooms
    const bathrooms = props.bathrooms
    const floorEx = props.floorEx

    
    //Common just not in Land nor in Office
    
    const buildingStatus = props.buildingStatus
    
    //Common just not in Land nor in House

    const floorAll = props.floorAll
    const projectType = props.projectType

    //Office Specific ---------------------------------------------------------------

    const officeType = props.officeType

    //House Specific ---------------------------------------------------------------

    const houseProjectType = props.houseProjectType
    const gardenArea = 100
    
    //Land Specific ---------------------------------------------------------------
    const landStatus = props.landStatus



    const amenityMap = {
        'აივანი': 'balcony',
        'პარკინგი': 'parking',
        'ვერანდა': 'veranda',
        'ცენტრალური გათბობა': 'centralHeating',
        'ცხელი წყალი': 'hotWater',
        'სპორტდარბაზი': 'gym',
        'ბუნებრივი აირი': 'gas',
        'ლიფტი': 'elevator',
        'ავეჯი': 'furniture',
        'ტელევიზორი': 'tv',
        'ინტერნეტი': 'internet',
        'კონდიციონერი': 'ac',
        'წყალი': 'water'
    };
      
    const amenitiesObj = {};
        amenities.forEach((amenity) => {
            if (amenityMap.hasOwnProperty(amenity)) {
            amenitiesObj[amenityMap[amenity]] = true;
            }
    });
      
      
      
      
      
      

    const headline =  dealtype + ' ' + type + ' ' + district + 'ში'

    let mainData = {}
    let logos = {}


    if(type === 'ბინა' || type === "სასტუმრო"){
        mainData = {
            headline : headline,
            spcAddress : spcAddress,
            area : area,
            price : price,
            rooms : rooms,
            bedrooms : bedrooms,
            bathrooms : bathrooms,
            buildingCondition : buildingCondition,
            buildingStatus : buildingStatus,
            projectType : projectType,
            amenitiesObj : amenitiesObj,
            floorEx: floorEx,
            floorAll: floorAll,
            description: description

        }

        logos = theme === 'light' ? {
            AreaLogo: AreaLogo,
            Door: Door,
            Bed: Bed,
            BathTub: BathTub,
            Architecture: Architecture,
            Construction: Construction,
            Design: Design,
            Balcony: Balcony,
            FireLogo: FireLogo,
            Garage: Garage,
            HotWater: HotWater,
            Gas: Gas,
            Internet: Internet,
            Elevator: Elevator,
            Furniture: Furniture,
            Tv: Tv,
            AC: AC,
            Gym: Gym,
            Stairs: Stairs
        } : {
            AreaLogo: AreaLogoD,
            Door: DoorD,
            Bed: BedD,
            BathTub: BathTubD,
            Architecture: ArchitectureD,
            Construction: ConstructionD,
            Design: DesignD,
            Balcony: BalconyD,
            FireLogo: FireLogoD,
            Garage: GarageD,
            HotWater: HotWaterD,
            Gas: GasD,
            Internet: InternetD,
            Elevator: ElevatorD,
            Furniture: FurnitureD,
            Tv: TvD,
            AC: ACD,
            Gym: GymD,
            Stairs: StairsD
        };
    }





    if(type === 'სახლი'){
        mainData = {
            headline : headline,
            spcAddress : spcAddress,
            area : area,
            gardenArea : gardenArea,
            price : price,
            rooms : rooms,
            bedrooms : bedrooms,
            bathrooms : bathrooms,
            buildingCondition : buildingCondition,
            buildingStatus : buildingStatus,
            houseProjectType : houseProjectType,
            amenitiesObj : amenitiesObj,
            floorEx: floorEx,
            description: description


        }


        logos = theme === 'light' ? {
            AreaLogo: AreaLogo,
            Garden: Garden,
            Door: Door,
            Bed: Bed,
            BathTub: BathTub,
            Architecture: Architecture,
            Construction: Construction,
            Design: Design,
            Balcony: Balcony,
            FireLogo: FireLogo,
            Garage: Garage,
            HotWater: HotWater,
            Gas: Gas,
            Internet: Internet,
            Furniture: Furniture,
            Tv: Tv,
            AC: AC,
            Gym: Gym,
            Stairs: Stairs
        } : {
            AreaLogo: AreaLogoD,
            Garden: GardenD,
            Door: DoorD,
            Bed: BedD,
            BathTub: BathTubD,
            Architecture: ArchitectureD,
            Construction: ConstructionD,
            Design: DesignD,
            Balcony: BalconyD,
            FireLogo: FireLogoD,
            Garage: GarageD,
            HotWater: HotWaterD,
            Gas: GasD,
            Internet: InternetD,
            Furniture: FurnitureD,
            Tv: TvD,
            AC: ACD,
            Gym: GymD,
            Stairs: StairsD
        };
    }


    if(type === 'საოფისე ფართი'){
        mainData = {
            headline : headline,
            spcAddress : spcAddress,
            area : area,
            price : price,
            rooms : rooms,
            bedrooms : bedrooms,
            bathrooms : bathrooms,
            buildingCondition : buildingCondition,
            projectType : projectType,
            officeType: officeType,
            amenitiesObj : amenitiesObj,
            floorEx: floorEx,
            floorAll: floorAll,
            description: description

        }


        logos = theme === 'light' ? {
            AreaLogo: AreaLogo,
            Door: Door,
            Bed: Bed,
            BathTub: BathTub,
            Architecture: Architecture,
            Construction: Construction,
            Design: Design,
            Balcony: Balcony,
            FireLogo: FireLogo,
            Garage: Garage,
            HotWater: HotWater,
            Gas: Gas,
            Internet: Internet,
            Elevator: Elevator,
            Furniture: Furniture,
            Tv: Tv,
            AC: AC,
            Gym: Gym,
            Stairs: Stairs
        } : {
            AreaLogo: AreaLogoD,
            Door: DoorD,
            Bed: BedD,
            BathTub: BathTubD,
            Architecture: ArchitectureD,
            Construction: ConstructionD,
            Design: DesignD,
            Balcony: BalconyD,
            FireLogo: FireLogoD,
            Garage: GarageD,
            HotWater: HotWaterD,
            Gas: GasD,
            Internet: InternetD,
            Elevator: ElevatorD,
            Furniture: FurnitureD,
            Tv: TvD,
            AC: ACD,
            Gym: GymD,
            Stairs: StairsD
        };
    }

    if(type === 'მიწის ნაკვეთი'){
        mainData = {
            headline : headline,
            spcAddress : spcAddress,
            area : area,
            price : price,
            amenitiesObj : amenitiesObj,
            landStatus : landStatus,
            description: description


        }

        logos = theme === 'light' ? {
            AreaLogo: AreaLogo,
            Architecture: Architecture,
            HotWater: HotWater,
            Gas: Gas,
            Canalisation: Canalisation,
            Electricity: Electricity,
            Road: Road
        } : {
            AreaLogo: AreaLogoD,
            Architecture: ArchitectureD,
            HotWater: HotWaterD,
            Gas: GasD,
            Canalisation: CanalisationD,
            Electricity: ElectricityD,
            Road: RoadD
        };
    }



    useEffect(() => {
        const descElement = document.getElementById('desc');
        if (descElement.scrollHeight > descElement.offsetHeight) {
          setShowButton(true);
        }
      }, []);
    
      const handleToggleExpand = () => {
        setExpanded(!expanded);
    };


    return (
        <div className='left-panel'>
            {type === 'სახლი' ? (
                <Houseview
                mainData={mainData}
                logos={logos}
                expanded={expanded}
                setExpanded={setExpanded}
                showButton={showButton}
                setShowButton={setShowButton}
                handleToggleExpand={handleToggleExpand}
                />
            ) : (
                <></>
            )}

            {type === 'ბინა' || type === 'სასტუმრო' ? (
                <Flatview
                mainData={mainData}
                logos={logos}
                expanded={expanded}
                setExpanded={setExpanded}
                showButton={showButton}
                setShowButton={setShowButton}
                handleToggleExpand={handleToggleExpand}
                />
            ) : (
                <></>
            )}

            {type === 'საოფისე ფართი' ? (
                <Officeview
                mainData={mainData}
                logos={logos}
                expanded={expanded}
                setExpanded={setExpanded}
                showButton={showButton}
                setShowButton={setShowButton}
                handleToggleExpand={handleToggleExpand}
                />
            ) : (
                <></>
            )}

            {type === 'მიწის ნაკვეთი' ? (
                <Landview
                mainData={mainData}
                logos={logos}
                expanded={expanded}
                setExpanded={setExpanded}
                showButton={showButton}
                setShowButton={setShowButton}
                handleToggleExpand={handleToggleExpand}
                />
            ) : (
                <></>
            )}
        </div>
    )
}

export default LeftPanel