import React, { useEffect, useState } from 'react';
import axios from "axios"
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react'
import "swiper/css"
import "./Residencies.css"
import data from '../../../utils/slider.json'
import { Link } from 'react-router-dom';
import { sliderSettings } from "../../../utils/common"

const Residencies = () => {


    const [statementIds, setStatementIds] = useState([]);
    const [statements, setStatements] = useState([]);

    useEffect(() => {
        axios
          .get('https://tbilisirealtor-api.onrender.com/api/property/get-allPop-ids')
          .then((response) => {
            setStatementIds(response.data);
          })
          .catch((error) => {
            console.error('Error fetching popular IDs:', error);
          });
    }, []);




    // Second useEffect to fetch individual statements
    useEffect(() => {
        const fetchStatements = async () => {
        try {
            const statementPromises = statementIds.map((id) =>
            axios.get(`https://tbilisirealtor-api.onrender.com/api/property/find/${id}`)
            );

            const statementResponses = await Promise.all(statementPromises);

            const fetchedStatements = statementResponses.map((response) => response.data);
            setStatements(fetchedStatements);
        } catch (error) {
            console.error('Error fetching statements:', error);
        }
        };

        fetchStatements();
    }, [statementIds]);



    

    console.log(statements)





  return (
    <section id="residence-component" className="r-wrapper">
        <div className="paddings innerWidth r-container">

            <div className="r-head flexColStart">
                <span className='orangeText'>საუკეთესო არჩევანი</span>
                <span className='primaryText'>პოპულარული საცხოვრებლები</span>
            </div>

            {statements.length > 0 && statements[0] && Object.keys(statements[0]).length > 0 && (
                <Swiper {...sliderSettings}>
                    <SliderButtons />
                    {statements.map((card, i) => (
                        <SwiperSlide key={i}>
                            <Link to={`/ad/${card.ad_id}`}>
                                <div className="flexColStart r-card">
                                    {card.images && card.images.length > 0 ? (
                                        <img src={card.images[0]} alt="home" />
                                    ) : (
                                        <img src="placeholder-image.jpg" alt="Placeholder" />
                                    )}
                                    
                                    <span className="secondaryText r-price">
                                        <span style={{ color: 'orange' }}>$</span>
                                        <span>{card.price}</span>
                                    </span>

                                    <span className='primaryText'>{card.dealType.display_name} {card.buildingStatus} {card.type.display_name}</span>
                                    <span className='secondaryText'>{card.detail}</span>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}


        </div>
    </section>
  )
}

export default Residencies

const SliderButtons = () => {
    const swiper = useSwiper()
    return(
        <div className="flexCenter r-buttons">
            <button onClick={() => swiper.slidePrev()}>&lt;</button>
            <button onClick={() => swiper.slideNext()}>&gt;</button>
        </div>
    )
}