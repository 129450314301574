import React, { useState, useEffect, useRef } from 'react'
import './AreaDrop.css'

const AreaDrop = ({ areaRange, setAreaRange }) => {
  const [isActive, setIsActive] = useState(false);
  const rooms = ['სტუდიო', '2', '3', '4', '5', '5+']
  const dropdownRef = useRef(null);




  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);




  const handleSave = () => {
    setAreaRange({
      ...areaRange,
      startArea: areaRange.startArea,
      endArea: areaRange.endArea
    });
    setIsActive(false);
  };



    // Updating the dealtype value
    const updateRooms = (option) => {
      if (areaRange.rooms.includes(option)) {
      setAreaRange((prevState) => ({
          ...prevState,
          rooms: prevState.rooms.filter((value) => value !== option),
      }));
      } else {
      setAreaRange((prevState) => ({
          ...prevState,
          rooms: [...prevState.rooms, option],
      }));
      }
  };

  function renderspan(){
    if(areaRange.startArea && areaRange.endArea){
      return(
        <span>
          {areaRange.startArea + " მ² - " + areaRange.endArea + " მ²"}
        </span>
      )
    }
    else if (areaRange.startArea){
      return(
        <span>
          {areaRange.startArea + "მ² +"}
        </span>
      )
    }
    else if (areaRange.endArea){
      return(
        <span>
          {"0 მ² - " +areaRange.endArea + " მ²"}
        </span>
      )
    }
    else{
      return <span>ფართი</span>
    }

  }


  return (
    <div className="areadrop">
      <div className="dropdown-btn" onClick={() => setIsActive(!isActive)}>
        <span>{renderspan()}</span>
      </div>
      {isActive && (
        <div className="dropdown-content" ref={dropdownRef}>
          <p className="head-text">მიუთითეთ ფართი</p>
          <div className="top">
            <div className="left">
              <input
                type="text"
                className="price-input"
                placeholder="დან"
                value={areaRange.startArea}
                onChange={(e) =>
                  setAreaRange({
                    ...areaRange,
                    startArea: e.target.value
                  })
                }
              />
            </div>
            <div className="right">
              <input
                type="text"
                className="price-input"
                placeholder="მდე"
                value={areaRange.endArea}
                onChange={(e) =>
                  setAreaRange({
                    ...areaRange,
                    endArea: e.target.value
                  })
                }
              />
            </div>
          </div>
          <div className="hor-line"></div>
          <div className="bottom">

            <div className="upper">
              {rooms.map((option) => (
                <div
                    key={option}
                    onClick={() => updateRooms(option)}
                    className={`room-item ${areaRange.rooms.includes(option) ? 'selected' : ''}`}
                    >
                    {option}
                </div>
                ))}
            </div>
            
            <div className="hor-line"></div>

            <div className="lower">
              <button className="dropbutton" onClick={handleSave}>
                შენახვა
              </button>
            </div>

          </div>
        </div>
      )}
    </div>
  )
}

export default AreaDrop