import React from 'react'
import "./LightSwitch.css"

const LightSwitch = ({ onChange, checked }) => {
  return (
    <div className='lightSwitch'>
        <input type='checkbox' id='time' onChange={onChange} checked={checked}/>
        <label for='time'>Night</label>
    </div>
  )
}

export default LightSwitch