
export const CITIES = [
    {
        id: 1,
        display_name: "თბილისი",
        districts: [
            {
                id: 1,
                display_name: "დიდუბე",
                urbans: [
                    {
                        id: 1,
                        display_name: 'დიდუბე'
                    },
                    {
                        id: 2,
                        display_name: 'დიღმის მასივი'
                    }
                ],
            },
            {
                id: 2,
                display_name: "ვაკე",
                urbans: [
                    {
                        id: 3,
                        display_name: 'აგარაკი'
                    },
                    {
                        id: 4,
                        display_name: 'ახალდაბა'
                    },
                    {
                        id: 5,
                        display_name: 'ბაგები'
                    },
                    {
                        id: 6,
                        display_name: 'ბეთანია'
                    },
                    {
                        id: 7,
                        display_name: 'ვაკე'
                    },
                    {
                        id: 8,
                        display_name: 'თხინვალა'
                    },
                    {
                        id: 9,
                        display_name: 'კვესეთი'
                    },
                    {
                        id: 10,
                        display_name: 'სტუდენტების დას.'
                    },
                    {
                        id: 11,
                        display_name: 'წყნეთი'
                    },
                ],
            },
            {
                id: 3,
                display_name: "ისანი",
                urbans: [
                    {
                        id: 12,
                        display_name: 'ავლაბარი'
                    },
                    {
                        id: 13,
                        display_name: 'ელია'
                    },
                    {
                        id: 14,
                        display_name: 'ვაზისუბანი'
                    },
                    {
                        id: 15,
                        display_name: 'ისანი'
                    },
                    {
                        id: 16,
                        display_name: 'მეტეხი'
                    },
                    {
                        id: 17,
                        display_name: 'მეტრომშენი'
                    },
                    {
                        id: 18,
                        display_name: 'ნავთლუღი'
                    },
                    {
                        id: 19,
                        display_name: 'ოცდამეთერთმეტე'
                    },
                    {
                        id: 20,
                        display_name: 'სამგორი'
                    },
                ],
            },
            {
                id: 4,
                display_name: "კრწანისი",
                urbans: [
                    {
                        id: 21,
                        display_name: 'აბანოთუბანი'
                    },
                    {
                        id: 22,
                        display_name: 'ბეთლემისკლდის უბანი'
                    },
                    {
                        id: 23,
                        display_name: 'ზემო ფონიჭალა'
                    },
                    {
                        id: 24,
                        display_name: 'კალაუბანი'
                    },
                    {
                        id: 25,
                        display_name: 'ორთაჭალა'
                    },
                    {
                        id: 26,
                        display_name: 'სოღანლუღი'
                    },
                    {
                        id: 27,
                        display_name: 'ფონიჭალა'
                    },
                    {
                        id: 28,
                        display_name: 'ქვემო ფონიჭალა'
                    },
                    {
                        id: 29,
                        display_name: 'ხარფუხი'
                    },
                ],
            },
            {
                id: 5,
                display_name: "მთაწმინდა",
                urbans: [
                    {
                        id: 30,
                        display_name: 'გარეთუბანი'
                    },
                    {
                        id: 31,
                        display_name: 'ვერა'
                    },
                    {
                        id: 32,
                        display_name: 'ზემო ვერა'
                    },
                    {
                        id: 33,
                        display_name: 'კიკეთი'
                    },
                    {
                        id: 34,
                        display_name: 'კოჯორი'
                    },
                    {
                        id: 35,
                        display_name: 'კრწანისი'
                    },
                    {
                        id: 36,
                        display_name: 'მთაწმინდა'
                    },
                    {
                        id: 37,
                        display_name: 'ოქროყანა'
                    },
                    {
                        id: 38,
                        display_name: 'სამადლო'
                    },
                    {
                        id: 39,
                        display_name: 'სოლოლაკი'
                    },
                    {
                        id: 40,
                        display_name: 'ტაბახმელა'
                    },
                    {
                        id: 41,
                        display_name: 'ფიქრისგორა'
                    },
                    {
                        id: 42,
                        display_name: 'შინდისი'
                    },
                    {
                        id: 43,
                        display_name: 'წავკისი'
                    },
                    {
                        id: 44,
                        display_name: 'წავკისის ველი'
                    },
                ],
            },
            {
                id: 6,
                display_name: "ნაძალადევი",
                urbans: [
                    {
                        id: 45,
                        display_name: 'ავშნიანი'
                    },
                    {
                        id: 46,
                        display_name: 'ნაძალადევი'
                    },
                    {
                        id: 47,
                        display_name: 'ლოტკინი'
                    },
                    {
                        id: 48,
                        display_name: 'მეტრო გურამიშვილი'
                    },
                    {
                        id: 49,
                        display_name: 'მეტრო ღრმაღელე'
                    },
                    {
                        id: 50,
                        display_name: 'სანზონა'
                    },
                    {
                        id: 51,
                        display_name: 'ფეიქრები'
                    }
                ],
            },
            {
                id: 7,
                display_name: "საბურთალო",
                urbans: [
                    {
                        id: 52,
                        display_name: 'დიდგორი'
                    },
                    {
                        id: 53,
                        display_name: 'დიდი დიღომი'
                    },
                    {
                        id: 54,
                        display_name: 'დიღმის ჭალა'
                    },
                    {
                        id: 55,
                        display_name: 'ვაჟა-ფშაველას კვარტლები'
                    },
                    {
                        id: 56,
                        display_name: 'ვაშლიჯვარი'
                    },
                    {
                        id: 57,
                        display_name: 'ვეძისი'
                    },
                    {
                        id: 58,
                        display_name: 'თბილისის დიპლომატიური სოფელი'
                    },
                    {
                        id: 59,
                        display_name: 'თელოვანი'
                    },
                    {
                        id: 60,
                        display_name: 'ლისი'
                    },
                    {
                        id: 61,
                        display_name: 'ლისის ტბის მიმდებარე'
                    },
                    {
                        id: 62,
                        display_name: 'საბურთალო'
                    },
                    {
                        id: 63,
                        display_name: 'სოფელი დიღომი'
                    },
                    {
                        id: 64,
                        display_name: 'ქვემო ლისი'
                    },
                    {
                        id: 65,
                        display_name: 'ქოშიგორა'
                    },
                    {
                        id: 66,
                        display_name: 'ძველი ვეძისი'
                    },
                    {
                        id: 67,
                        display_name: 'ნუცუბიძის პლატოები'
                    }
                ],
            },
            {
                id: 8,
                display_name: "სამგორი",
                urbans: [
                    {
                        id: 68,
                        display_name: 'აეროპორტის დას.'
                    },
                    {
                        id: 69,
                        display_name: 'ალექსეევკა'
                    },
                    {
                        id: 70,
                        display_name: 'აფრიკა'
                    },
                    {
                        id: 71,
                        display_name: 'დამპალო'
                    },
                    {
                        id: 72,
                        display_name: 'დიდი ლილო'
                    },
                    {
                        id: 73,
                        display_name: 'ვარკეთილი'
                    },
                    {
                        id: 74,
                        display_name: 'თბილისის ზღვის ახალი ქალაქი'
                    },
                    {
                        id: 75,
                        display_name: 'ლილოს დას.'
                    },
                    {
                        id: 76,
                        display_name: 'მესამე მასივი'
                    },
                    {
                        id: 77,
                        display_name: 'მოსკოვის გამზ.'
                    },
                    {
                        id: 78,
                        display_name: 'ნავთლუღი'
                    },
                    {
                        id: 79,
                        display_name: 'ნასაგური'
                    },
                    {
                        id: 80,
                        display_name: 'ორხევი'
                    }
                ],
            },
            {
                id: 9,
                display_name: "ჩუღურეთი",
                urbans: [
                    {
                        id: 81,
                        display_name: 'ვორონცოვი'
                    },
                    {
                        id: 82,
                        display_name: 'პლეხანოვი'
                    },
                    {
                        id: 83,
                        display_name: 'ივერთუბანი'
                    },
                    {
                        id: 84,
                        display_name: 'კუკია'
                    },
                    {
                        id: 85,
                        display_name: 'სვანეთის უბანი'
                    },
                    {
                        id: 86,
                        display_name: 'ოქროს უბანი'
                    },
                    {
                        id: 87,
                        display_name: 'ჩუღურეთი'
                    }
                ],
            },
            {
                id: 10,
                display_name: "თბილისის შემ",
                urbans: [
                    {
                        id: 88,
                        display_name: 'კიკეთი'
                    },
                    {
                        id: 89,
                        display_name: 'კოჯორი'
                    },
                    {
                        id: 90,
                        display_name: 'თელოვანი'
                    },
                    {
                        id: 91,
                        display_name: 'ოქროყანა'
                    },
                    {
                        id: 92,
                        display_name: 'ტაბახმელა'
                    },
                    {
                        id: 93,
                        display_name: 'შინდისი'
                    },
                    {
                        id: 94,
                        display_name: 'წავკისი'
                    }
                ],
            },
            {
                id: 11,
                display_name: "გლდანი",
                urbans: [
                    {
                        id: 95,
                        display_name: 'ავჭალა'
                    },
                    {
                        id: 96,
                        display_name: 'ბერთუბანი'
                    },
                    {
                        id: 97,
                        display_name: 'გიორგი წმინდას დას.'
                    },
                    {
                        id: 98,
                        display_name: 'გლდანის მასივი'
                    },
                    {
                        id: 99,
                        display_name: 'გლდანულა'
                    },
                    {
                        id: 100,
                        display_name: 'ზაჰესი'
                    },
                    {
                        id: 101,
                        display_name: 'ზემო ავჭალა'
                    },
                    {
                        id: 102,
                        display_name: 'კონიაკის დას.'
                    },
                    {
                        id: 103,
                        display_name: 'მუხიანი'
                    },
                    {
                        id: 104,
                        display_name: 'მუხიანის დაჩები'
                    },
                    {
                        id: 105,
                        display_name: 'სოფელი გლდანი'
                    },
                ],
            },
        ]
    },
    {
        id: 2,
        display_name: "ბათუმი",
        districts: []
    },
    {
        id: 3,
        display_name: "ქუთაისი",
        districts: []
    },
    {
        id: 4,
        display_name: "რუსთავი",
        districts: []
    }
]

