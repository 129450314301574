import React, { useEffect, useState } from 'react';
import CardList from '../../components/CardList/CardList';
import Pagination from '../../components/Pagination/Pagination';

import SearchBar from "../../components/SearchBar/SearchBar"

import "./Listing.css"

const Listing = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10)

  const handleSearch = (finalData) => {
    setProducts(finalData);
    console.log(finalData)
  };




  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  let currentPosts;

  if(products){
      if (products.length > 1) {
        currentPosts = products.slice(firstPostIndex, lastPostIndex);
      } else {
        currentPosts = products;
      }
  }
  return (
    <div>
      <SearchBar onSearch={handleSearch}/>

      {currentPosts.length === 0 ? (
        <div className="warning-wrapper">
          <h1 className='warning'>განცხადება ვერ მოიძებნა</h1>
        </div>
      ) : (
        <CardList products={currentPosts} />
      )}

      <Pagination 
        totalPosts={products.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default Listing;