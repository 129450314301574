import React, { useState, useEffect, useRef } from "react"
import "./DealTypeDrop.css"


const DealTypeDrop = ({selectedDeal, setSelectedDeal}) => {
    const [isActive, setIsActive] = useState(false)


    const dropdownRef = useRef(null);




    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsActive(false);
        }
      };
  
      document.addEventListener('mousedown', handleOutsideClick);
  
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, []);




    const dealTypeOptions = [
        {
            id: 1,
            display_name: 'იყიდება',
        },
        {
            id: 2,
            display_name: "ქირავდება"
        },
        {
            id: 3,
            display_name: 'გირავდება'
        },
        {
            id: 4,
            display_name: 'ქირავდება დღიურად'
        }
    ]


    const propertyTypeOptions = [
        {
            id: 1,
            display_name: 'ბინა',
        },
        {
            id: 2,
            display_name: "სახლი"
        },
        {
            id: 3,
            display_name: 'საოფისე ფართი'
        },
        {
            id: 4,
            display_name: 'სასტუმრო'
        },
        {
            id: 5,
            display_name: 'მიწა'
        }
    ]


    const hasDealType = selectedDeal.dealtype.length > 0;
    const hasPropertyType = selectedDeal.propertyType.length > 0;


    // Updating the dealtype value
    const updateDealType = (option) => {
        if (selectedDeal.dealtype.includes(option.display_name)) {
        setSelectedDeal((prevState) => ({
            ...prevState,
            dealtype: prevState.dealtype.filter((value) => value !== option.display_name),
        }));
        } else {
        setSelectedDeal((prevState) => ({
            ...prevState,
            dealtype: [...prevState.dealtype, option.display_name],
        }));
        }
    };

    // Updating the propertyType value
    const updatePropertyType = (option) => {
        if (selectedDeal.propertyType.includes(option.display_name)) {
        setSelectedDeal((prevState) => ({
            ...prevState,
            propertyType: prevState.propertyType.filter((value) => value !== option.display_name),
        }));
        } else {
        setSelectedDeal((prevState) => ({
            ...prevState,
            propertyType: [...prevState.propertyType, option.display_name],
        }));
        }
    };

    const handleDealtypeIds = (selectedDealtypeId) => {
        if (selectedDeal.selectedDealtypeIds.includes(selectedDealtypeId)) {
          setSelectedDeal((prevState) => ({
            ...prevState,
            selectedDealtypeIds: prevState.selectedDealtypeIds.filter((index) => index !== selectedDealtypeId),
          }));
        } else {
            setSelectedDeal((prevState) => ({
            ...prevState,
            selectedDealtypeIds: [...prevState.selectedDealtypeIds, selectedDealtypeId],
          }));
        }
    };



    const handleTypeIds = (selecteTypeId) => {
        if (selectedDeal.selectedTypeIds.includes(selecteTypeId)) {
          setSelectedDeal((prevState) => ({
            ...prevState,
            selectedTypeIds: prevState.selectedTypeIds.filter((index) => index !== selecteTypeId),
          }));
        } else {
            setSelectedDeal((prevState) => ({
            ...prevState,
            selectedTypeIds: [...prevState.selectedTypeIds, selecteTypeId],
          }));
        }
    };

    function renderContent() {
        if ((hasDealType || hasPropertyType) && (selectedDeal.dealtype.length + selectedDeal.propertyType.length > 2)) {
          const selectedObjects = [...selectedDeal.dealtype, ...selectedDeal.propertyType];
          const displayedObjects = selectedObjects.slice(0, 2);
          return (
            <span>
              {displayedObjects.join(", ")}
              {selectedObjects.length > 2 && "..."}
            </span>
          );
        }
        else if((hasDealType || hasPropertyType) && (selectedDeal.dealtype.length + selectedDeal.propertyType.length < 3)){
            const selectedObjects = [...selectedDeal.dealtype, ...selectedDeal.propertyType];
            const displayedObjects = selectedObjects.slice(0, 2);
            return(
                <span>
                    {displayedObjects.join(", ")}
                </span>
            )
        }
        else {
          return <span>გარიგების და ქონების ტიპი</span>;
        }
      }

    return (
        <div className="dealtypedrop">
            <div className="dropdown-btn" onClick={() => setIsActive(!isActive)}>
                <span>{renderContent()}</span>

            </div>
            {isActive && (
                <div className="dropdown-content" ref={dropdownRef}>
                    <div className="upper">
                        <p className="head-text">გარიგების ტიპი</p>
                        <div className="deal-content">
                            {dealTypeOptions.map((option) => (
                            <div
                                key={option.id}
                                id={option.id}
                                onClick={() => {
                                    updateDealType(option)
                                    handleDealtypeIds(option.id)
                                }}
                                className={`dropdown-item ${selectedDeal.dealtype.includes(option.display_name) ? 'selected' : ''}`}
                                >
                                {option.display_name}
                            </div>
                            ))}
                        </div>
                    </div>
                    <div className="lower">
                    <p className="head-text">ქონების ტიპი</p>
                        <div className="property-content">
                            {propertyTypeOptions.map((option) => (
                            <div
                                key={option.id}
                                id={option.id}
                                onClick={() => {
                                    updatePropertyType(option)
                                    handleTypeIds(option.id)
                                }}
                                className={`dropdown-item ${selectedDeal.propertyType.includes(option.display_name) ? 'selected' : ''}`}
                                >
                                {option.display_name}
                            </div>
                            ))}
                        </div>
                    </div>
                    
                    <div className="line"></div>
                    
                    <div className="button-space">
                        <button className="dropbutton" onClick={() => setIsActive(!isActive)}> შენახვა </button>
                    </div>
                </div>
            )}
      </div>
    )
}

export default DealTypeDrop