import React, {useState, useEffect, useRef} from 'react'
import './LocationDrop.css'

import { CITIES } from '../../../utils/locationData'


const LocationDrop = ({location, setLocation}) => {

  const [isActive, setIsActive] = useState(false)

  const dropdownRef = useRef(null);




  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // Updating the City value
  const updateCity = (option) => {
    if (location.city.includes(option)) {
    setLocation((prevState) => ({
        ...prevState,
        city: prevState.city.filter((value) => value !== option),
        district: [],
        urban: [],
        selectedDistrictIds: [],
        selectedUrbanIds: [],
        selectedDistrictIndexes: [],

    }));

    } else {
    setLocation((prevState) => ({
        ...prevState,
        city: [...prevState.city, option],
    }));
    }
  };


  // Updating the District value
  const updateDistrict = (option) => {
    if (location.district.includes(option)) {
    setLocation((prevState) => ({
        ...prevState,
        district: prevState.district.filter((value) => value !== option),
        urban: [],
        selectedUrbanIds: [],
    }));
    } else {
    setLocation((prevState) => ({
        ...prevState,
        district: [...prevState.district, option],
    }));
    }
  };



  // Updating the Urban value
  const updateUrban = (option) => {
    if (location.urban.includes(option)) {
    setLocation((prevState) => ({
        ...prevState,
        urban: prevState.urban.filter((value) => value !== option),
    }));
    } else {
    setLocation((prevState) => ({
        ...prevState,
        urban: [...prevState.urban, option],
    }));
    }
  };


  if (!location.selectedCityIndexes) {
    setLocation((prevState) => ({
      ...prevState,
      selectedCityIndexes: [],
    }));
  }


  const handleCitySelect = (selectedCityIndex) => {
    if (location.selectedCityIndexes.includes(selectedCityIndex)) {
      setLocation((prevState) => ({
        ...prevState,
        selectedCityIndexes: prevState.selectedCityIndexes.filter((index) => index !== selectedCityIndex),
      }));
    } else {
      setLocation((prevState) => ({
        ...prevState,
        selectedCityIndexes: [...prevState.selectedCityIndexes, selectedCityIndex],
      }));
    }
  };

  
  
  
  const handleDistrictSelect = (selectedDistrictIndex) => {
    if (location.selectedDistrictIndexes.includes(selectedDistrictIndex)) {
      setLocation((prevState) => ({
        ...prevState,
        selectedDistrictIndexes: prevState.selectedDistrictIndexes.filter((index) => index !== selectedDistrictIndex),
      }));
    } else {
      setLocation((prevState) => ({
        ...prevState,
        selectedDistrictIndexes: [...prevState.selectedDistrictIndexes, selectedDistrictIndex],
      }));
    }
  };






  const handleCityIds = (selectedCityId) => {
    if (location.selectedCityIds.includes(selectedCityId)) {
      setLocation((prevState) => ({
        ...prevState,
        selectedCityIds: prevState.selectedCityIds.filter((index) => index !== selectedCityId),
      }));
    } else {
      setLocation((prevState) => ({
        ...prevState,
        selectedCityIds: [...prevState.selectedCityIds, selectedCityId],
      }));
    }
  };
  




  const handleDistrictIds = (selectedDistrictId) => {
    if (location.selectedDistrictIds.includes(selectedDistrictId)) {
      setLocation((prevState) => ({
        ...prevState,
        selectedDistrictIds: prevState.selectedDistrictIds.filter((index) => index !== selectedDistrictId),
      }));
    } else {
      setLocation((prevState) => ({
        ...prevState,
        selectedDistrictIds: [...prevState.selectedDistrictIds, selectedDistrictId],
      }));
    }
  };
  
  


  const handleUrbanIds = (selectedUrbanId) => {
    if (location.selectedUrbanIds.includes(selectedUrbanId)) {
      setLocation((prevState) => ({
        ...prevState,
        selectedUrbanIds: prevState.selectedUrbanIds.filter((index) => index !== selectedUrbanId),
      }));
    } else {
      setLocation((prevState) => ({
        ...prevState,
        selectedUrbanIds: [...prevState.selectedUrbanIds, selectedUrbanId],
      }));
    }
  };

  const hasCity = location.city.length > 0;
  const hasDistrict = location.district.length > 0;
  const hasUrban = location.urban.length > 0;


  function renderContent() {
    if ((hasCity || hasDistrict || hasUrban) && (location.city.length + location.district.length + location.urban.length > 3)) {
      const selectedObjects = [...location.city, ...location.district, ...location.urban];
      const displayedObjects = selectedObjects.slice(0, 2).map(obj => obj.display_name);
      return (
        <span>
          {displayedObjects.join(", ")}
          {selectedObjects.length > 3 && "..."}
        </span>
      );
    } else if ((hasCity || hasDistrict || hasUrban) && (location.city.length + location.district.length + location.urban.length < 4)) {
      const selectedObjects = [...location.city, ...location.district, ...location.urban];
      const displayedObjects = selectedObjects.map(obj => obj.display_name);
      return (
        <span>
          {displayedObjects.join(", ")}
        </span>
      );
    } else {
      return <span>აირჩიე მდებარეობა</span>;
    }
  }



  return (
    <div className="locationdrop">
      <div className="dropdown-btn" onClick={() => setIsActive(!isActive)}>
        <span>{renderContent()}</span>
      </div>

      {isActive && (
        
        <div className="dropdown-content" ref={dropdownRef}>


          <div className="left">
            <div className="city-part">
              {CITIES.map((option, index) => (
                <div
                    key={option.id}
                    id={option.id}
                    onClick={() => {
                      updateCity(option)
                      handleCitySelect(index)
                      handleCityIds(option.id)
                    }}
                    className={`dropdown-item ${location.city.includes(option) ? 'selected' : ''}`}
                    >
                    {option.display_name}
                </div>
              ))}
            </div>
          </div>
          {location.selectedCityIndexes.length > 0 && <div className="line-ver"></div>} {/* Add conditional rendering for the line */}
          

          <div className="center">
            <div className="district-part">
              {location.selectedCityIndexes.map((selectedIndex) =>
                CITIES[selectedIndex]?.districts.map((option, districtIndex) => (
                  <div
                    key={option.id}
                    id={option.id}
                    onClick={() => {
                      updateDistrict(option)
                      handleDistrictSelect(districtIndex)
                      handleDistrictIds(option.id)
                    }}
                    className={`dropdown-item ${location.district.includes(option) ? 'selected' : ''}`}
                  >
                    {option.display_name}
                  </div>
                ))
              )}
            </div>

          </div>

          <div className="right">
            <div className="urban-part-col1">
              {location.selectedCityIndexes.map((selectedIndex) =>
                CITIES[selectedIndex]?.districts.map((districtOption, districtIndex) =>
                  location.selectedDistrictIndexes.includes(districtIndex) ? (
                    districtOption.urbans.slice(0, Math.ceil(districtOption.urbans.length / 3)).map((option) => (
                      <div
                        key={option.id}
                        id={option.id}
                        onClick={() => {
                          updateUrban(option);
                          handleUrbanIds(option.id);
                        }}
                        className={`dropdown-item ${location.urban.includes(option) ? 'selected' : ''}`}
                      >
                        {option.display_name}
                      </div>
                    ))
                  ) : null
                )
              )}
            </div>
            <div className="urban-part-col2">
              {location.selectedCityIndexes.map((selectedIndex) =>
                CITIES[selectedIndex]?.districts.map((districtOption, districtIndex) =>
                  location.selectedDistrictIndexes.includes(districtIndex) ? (
                    districtOption.urbans.slice(Math.ceil(districtOption.urbans.length / 3), Math.ceil((districtOption.urbans.length / 3) * 2)).map((option) => (
                      <div
                        key={option.id}
                        id={option.id}
                        onClick={() => {
                          updateUrban(option);
                          handleUrbanIds(option.id);
                        }}
                        className={`dropdown-item ${location.urban.includes(option) ? 'selected' : ''}`}
                      >
                        {option.display_name}
                      </div>
                    ))
                  ) : null
                )
              )}
            </div>
            <div className="urban-part-col3">
              {location.selectedCityIndexes.map((selectedIndex) =>
                CITIES[selectedIndex]?.districts.map((districtOption, districtIndex) =>
                  location.selectedDistrictIndexes.includes(districtIndex) ? (
                    districtOption.urbans.slice(Math.ceil((districtOption.urbans.length / 3) * 2)).map((option) => (
                      <div
                        key={option.id}
                        id={option.id}
                        onClick={() => {
                          updateUrban(option);
                          handleUrbanIds(option.id);
                        }}
                        className={`dropdown-item ${location.urban.includes(option) ? 'selected' : ''}`}
                      >
                        {option.display_name}
                      </div>
                    ))
                  ) : null
                )
              )}
            </div>
          </div>

        </div>
      )}

    </div>
  )
}

export default LocationDrop