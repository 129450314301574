import React from 'react'
import "../LeftPanel.css"


const Landview = ({mainData, logos, expanded, setExpanded, showButton, setShowButton, handleToggleExpand }) => {
     
    const { headline, spcAddress, area, price, landStatus, amenitiesObj, description } = mainData;
    const { AreaLogo, Architecture, HotWater, Gas, Electricity, Canalisation, Road,  } = logos;




    return (
        <div className="lp-wrapper">


            <div className="address">
                <p className="main-address">{headline}</p>
                <p className="specific-address">{spcAddress}</p>
            </div>
            <div className="area-wrapper">

                <div className="area-item">
                    <div className="img-container">
                        <img src={AreaLogo} alt="" />
                    </div>
                    <span>- {area}მ²</span>
                </div>

            </div>
            <div className="price">
                ${price}<span style={{color: 'grey', fontSize: '12px'}}>/თვე</span>
            </div>


            
            <div
                id="desc"
                className={`desc ${expanded ? 'expanded' : ''}`}
            >
                <p>{description}</p>
            </div>
            {showButton && (
                <p
                className="read-more"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={handleToggleExpand}
                >
                {expanded ? 'Read less' : 'Read more'}
                </p>
            )}


            <div className="lower-stats">

                <div className="down-row">

                    <h2>სივრცე</h2>

                    <div className="down-row-item">
                        <div className="img-container">
                            <img src={Architecture} alt="" />
                        </div>
                        <p>{landStatus}</p>
                    </div>

                </div>

                <div className="down-row">

                    <h2>კეთილმოწყობა</h2>

                    <div className={`down-row-item ${amenitiesObj.water ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={HotWater} alt="" />
                        </div>
                        <p>წყალი</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.gas ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Gas} alt="" />
                        </div>
                        <p>ბუნებრივი აირი</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.gym ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Canalisation} alt="" />
                        </div>
                        <p>კანალიზაცია</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.gym ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Road} alt="" />
                        </div>
                        <p>ასფალტიანი გზა</p>
                    </div>
                    
                    <div className={`down-row-item ${amenitiesObj.gym ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Electricity} alt="" />
                        </div>
                        <p>ელ. ენერგია</p>
                    </div>

                </div>

            </div>
            
        </div>
    )
}

export default Landview