import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const Carousel = ({ images, startingIndex, onClose }) => {
  const handleContentClick = (event) => {
    event.stopPropagation();
  };

  const closeCarousel = () => {
    onClose();
  };

  const imgs = images.map((img) => ({
    original: img,
  }));

  return (
    <div className="modal" onClick={handleContentClick}>
      <button className="close" onClick={closeCarousel}>
        &times;
      </button>
      <ImageGallery 
      items={imgs} 
      startIndex={startingIndex} 
      showPlayButton={false}
      showFullscreenButton={false}
      showBullets={true}/>
      
    </div>
  );
};

export default Carousel;
