import React from 'react'
import './GetStarted.css'


const GetStarted = () => {
  return (
    <section  id="getstarted-component" className="g-wrapper">
        <div className="paddings innerWidth g-container">
            <div className="flexColCenter inner-container">
                <span className='primaryText'>გსურთ თქვენი საკუთრების გაყიდვა ან გაქირავება ?</span>
                <span className='secondaryText'>
                    გამოგვიგზავნეთ თქვენი უძრავი ქონება
                    <br/>
                    მოძებნეთ კლიენტი უსწრაფესად
                </span>
                <button className='button'>
                    <a href="mailto:tbilisirealt2@gmail.com">გამოაგზავნეთ აქ</a>
                </button>
            </div>
        </div>
    </section>
  )
}

export default GetStarted