import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import LeftPanel from '../../components/DetailedListingComps/LeftPanel/LeftPanel';
import RightPanel from '../../components/DetailedListingComps/RightPanel/RightPanel';

import './DetailedListing.css'

const ListingDetails = ({theme}) => {
  const { id } = useParams();
  const [listing, setListing] = useState(null);

  useEffect(() => {
    // Fetch the data for the listing with the given ID from your API or data source
    // Example code using fetch:
    fetch(`https://tbilisirealtor-api.onrender.com/api/property/find/${id}`)
      .then((response) => response.json())
      .then((data) => setListing(data))
      .catch((error) => {
        console.error('Error fetching listing:', error);
        
        // Handle error
      });
  }, [id]);

  if (!listing) {
    return <div>Loading...</div>;
  }

  return (
    <div className="listing-details-wrapper">


        <LeftPanel 
          props={listing}
          theme={theme}
        />



        <RightPanel 
          images={listing.images}
        />

    </div>
  );
};

export default ListingDetails;