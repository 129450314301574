import React, { useState } from 'react';
import axios from "axios"
import './AddStatement.css';
import FlatStatement from './FlatStatement/FlatStatement';
import HouseStatement from './HouseStatement/HouseStatement';
import OfficeStatement from './OfficeStatement/OfficeStatement';
import LandStatement from './LandStatement/LandStatement';

import Cookies from 'js-cookie';


import {CITIES} from "../../../utils/locationData"



const AddStatement = () => {

  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [isFormValid, setFormValid] = useState(true);
  
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isDropdownOpen, setDropdownOpen] = useState({});

  const [selectedIndexes, setSelectedIndexes] = useState({
    selectedCityIndex: null,
    selectedDistrictIndex: null
  });

  const token = Cookies.get('token'); // Get the token from cookies


  const citiesOptions = CITIES.map((city) => ({ id: city.id, display_name: city.display_name }))
  
  const districtsOptions = selectedIndexes.selectedCityIndex !== null
  ? CITIES[selectedIndexes.selectedCityIndex].districts.map(district => ({
      id: district.id,
      display_name: district.display_name
    }))
  : [];

  const urbanOptions = selectedIndexes.selectedCityIndex !== null &&
  selectedIndexes.selectedDistrictIndex !== null
  ? CITIES[selectedIndexes.selectedCityIndex].districts[selectedIndexes.selectedDistrictIndex].urbans.map(urban => ({
      id: urban.id,
      display_name: urban.display_name
    }))
  : [];

  
  const dropdownData = [
    {
      id: 'type',
      name: 'უძრავი ქონების ტიპი',
      options: [
        { id: 1, display_name: 'ბინა' },
        { id: 2, display_name: 'სახლი' },
        { id: 3, display_name: 'საოფისე ფართი' },
        { id: 4, display_name: 'სასტუმრო' },
        { id: 5, display_name: 'მიწის ნაკვეთი' },
      ],
    },
    {
      id: 'dealType',
      name: 'გარიგების ტიპი',
      options: [
        { id: 1, display_name: 'იყიდება' },
        { id: 2, display_name: 'ქირავდება' },
        { id: 3, display_name: 'გირავდება' },
        { id: 4, display_name: 'ქირავდება დღიურად' },
      ],
    },
    {
      id: 'city',
      name: 'ქალაქი',
      options: citiesOptions
    },
    {
      id: 'district',
      name: 'საერთო უბანი',
      options: districtsOptions
    },
    {
      id: 'urban',
      name: 'კონკრეტული უბანი',
      options: urbanOptions
    }
    
    
    // Add more dropdown data here...
  ];











  

  const toggleDropdown = (dropdownId) => {
    setDropdownOpen((prevOpenState) => ({
      ...prevOpenState,
      [dropdownId]: !prevOpenState[dropdownId],
    }));
  };

  const handleSelection = (dropdownId, optionId, optionName) => {
    if (dropdownId === 'city') {
      const selectedCityIndex = CITIES.findIndex(city => city.id === optionId);
      setSelectedIndexes(prevSelectedIndexes => ({
        ...prevSelectedIndexes,
        selectedCityIndex: selectedCityIndex !== -1 ? selectedCityIndex : null,
        selectedDistrictIndex: null  // Reset the selected district index when a new city is selected
      }));
  
      // Reset selected options and open state for child dropdowns
      setSelectedOptions(prevSelectedOptions => ({
        ...prevSelectedOptions,
        district: null,
        urban: null
      }));
      setDropdownOpen(prevOpenState => ({
        ...prevOpenState,
        district: false,
        urban: false
      }));
    } else if (dropdownId === 'district') {
      const selectedDistrictIndex = CITIES[selectedIndexes.selectedCityIndex].districts.findIndex(district => district.id === optionId);
      setSelectedIndexes(prevSelectedIndexes => ({
        ...prevSelectedIndexes,
        selectedDistrictIndex: selectedDistrictIndex !== -1 ? selectedDistrictIndex : null
      }));
  
      // Reset selected options and open state for child dropdown
      setSelectedOptions(prevSelectedOptions => ({
        ...prevSelectedOptions,
        urban: null
      }));
      setDropdownOpen(prevOpenState => ({
        ...prevOpenState,
        urban: false
      }));
    }
    
    setSelectedOptions(prevSelectedOptions => ({
      ...prevSelectedOptions,
      [dropdownId]: { id: optionId, display_name: optionName }
    }));
    
    setDropdownOpen(prevOpenState => ({
      ...prevOpenState,
      [dropdownId]: false
    }));
  };

  const renderDropdown = (dropdown) => {
    const { id, name, options } = dropdown;

    return (
      <div className={`${id}-drop`} key={id}>
        <button className={`drop-button ${isFormSubmitted && !selectedOptions[id] ? 'error' : ''}`} onClick={(e) => { e.stopPropagation(); toggleDropdown(id); }}>
          {selectedOptions[id] ? selectedOptions[id].display_name : name}
        </button>
        <div className={`drop-content ${isDropdownOpen[id] ? 'show' : ''}`}>
          {options.map((option) => (
            <span key={option.id} onClick={(e) => { e.stopPropagation(); handleSelection(id, option.id, option.display_name); }}>

              {option.display_name}
            </span>
          ))}
        </div>
      </div>
    );
  };



  const handleSelectedData = (data) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      ...data
    }));
  };

  const renderStatementComponent = () => {
    switch (selectedOptions.type && selectedOptions.type.id) {
      case 1:
      case 4:
        return <FlatStatement onSelectedData={handleSelectedData} isFormSubmitted={isFormSubmitted}/>;
      case 2:
        return <HouseStatement onSelectedData={handleSelectedData} isFormSubmitted={isFormSubmitted}/>;
      case 3:
        return <OfficeStatement onSelectedData={handleSelectedData} isFormSubmitted={isFormSubmitted}/>;
      case 5:
        return <LandStatement onSelectedData={handleSelectedData} isFormSubmitted={isFormSubmitted}/>;
      default:
        return null;
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
  
    let isFormValid = false
    // Check if all necessary fields are filled


    console.log(selectedOptions.type.id)

    
    if(selectedOptions.type){

      if(selectedOptions.type.id === 1 || selectedOptions.type.id === 4){
        const requiredFields = ['type', 'dealType', 'city', 'district', 'urban', 'exLocation', 'buildingStatus', 'projectType', 'buildingCondition', 'rooms', 'bedrooms', 'bathrooms', 'floorEx', 'floorAll', 'area', 'price']
        isFormValid = requiredFields.every((field) => selectedOptions[field]);
        setFormValid(isFormValid);
      }
      else if(selectedOptions.type.id === 2){
        const requiredFields = ['type', 'dealType', 'city', 'district', 'urban', 'exLocation', 'buildingStatus', 'houseProjectType', 'buildingCondition', 'rooms', 'bedrooms', 'bathrooms', 'floorEx', 'gardenArea', 'area', 'price']
        isFormValid = requiredFields.every((field) => selectedOptions[field]);
        setFormValid(isFormValid);
      }
      else if(selectedOptions.type.id === 3){
        const requiredFields = ['type', 'dealType', 'city', 'district', 'urban', 'exLocation', 'officeType', 'projectType', 'buildingCondition', 'rooms', 'bedrooms', 'bathrooms', 'floorEx', 'floorAll', 'area', 'price']
        isFormValid = requiredFields.every((field) => selectedOptions[field]);
        setFormValid(isFormValid);
      }
      else if(selectedOptions.type.id === 5){
        const requiredFields = ['type', 'dealType', 'city', 'district', 'urban', 'exLocation', 'landStatus', 'area', 'price']
        isFormValid = requiredFields.every((field) => selectedOptions[field]);
        setFormValid(isFormValid);
      }

    }


    




    if (isFormValid) {


      
      // Make the GET request to fetch the ad_id
      axios
        .get('https://tbilisirealtor-api.onrender.com/api/property/ad_id', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const ad_id = response.data.ad_id;
    
          const formData = new FormData();
    
          // Append the selectedOptions to the formData
          for (const key in selectedOptions) {
            if (selectedOptions.hasOwnProperty(key)) {
              // Skip the images property since it's handled separately
              if (key === 'images') continue;
    
              formData.append(key, JSON.stringify(selectedOptions[key]));
            }
          }
    
          // Append the ad_id to the formData
          formData.append('ad_id', ad_id);
    
          // Check if the images array exists
          if (selectedOptions.images && selectedOptions.images.length > 0) {
            // Append the images files to the formData
            for (let i = 0; i < selectedOptions.images.length; i++) {
              formData.append('images', selectedOptions.images[i]);
            }
          }
    
          const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          };
    
          // Make the POST request with the updated formData
          axios
            .post('https://tbilisirealtor-api.onrender.com/api/property/add-property', formData, {
              headers: headers,
            })
            .then((response) => {
              console.log(response.data);
              // Reset the form or show a success message
            })
            .catch((error) => {
              console.error(error);
              // Show an error message or handle the error accordingly
            });
        })
        .catch((error) => {
          console.error(error);
          // Show an error message or handle the error accordingly
        });


      setFormSubmitted(true);
      


      console.log(selectedOptions)
    }
    


  };
  



  
  return (
    <div className="add-statement-wrapper">
      <div className="add-statement-content">
        {!isFormValid && (
        <div className="error-message">Please fill in all the required fields</div>
        )}  

        <div className="common-information">
          <h2>ძირითადი ინფორმაცია</h2>
          {dropdownData.map(renderDropdown)}


          <input
          type="text"
          className='address-input'
          value={selectedOptions.exLocation || ''}
          onChange={(e) =>
            setSelectedOptions((prevSelectedOptions) => ({
              ...prevSelectedOptions,
              exLocation: e.target.value,
            }))
          }
          placeholder="მიუთითეთ კონკრეტული მისამართი"
          />
        </div>


        {renderStatementComponent()}
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};


export default AddStatement;
