import React, {useState, useEffect} from 'react'
import axios from "axios"
import Cookies from 'js-cookie';


import CardList from '../..//CardList/CardList';
import Pagination from '../../Pagination/Pagination';

import "./Statements.css"



const Statements = () => {
    const [statements, setStatements] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10)


    const token = Cookies.get('token');

    let isOwner = true

    useEffect(() => {
        // Function to fetch user properties
        const fetchUserProperties = async () => {
        try {
            // Send the GET request to the server
            const response = await axios.get('https://tbilisirealtor-api.onrender.com/api/property/getbyuser', {
            headers: {
                Authorization: `Bearer ${token}`, 
            },
            });
    
            // Update the state with the user properties received from the server
            setStatements(response.data);
            

        } catch (error) {
            console.error('Error fetching user properties:', error.message);
        }
        };
    
        // Call the function to fetch user properties when the component mounts
        fetchUserProperties();
    }, [token]);

    const lastPostIndex = currentPage * postsPerPage
    const firstPostIndex = lastPostIndex - postsPerPage
    let currentPosts;
    if (statements.length > 1) {
      currentPosts = statements.slice(firstPostIndex, lastPostIndex);
    } else {
      currentPosts = statements;
    }
    

    console.log(statements)
    return (
        <div className='listed-content'>
            
            {Array.isArray(currentPosts) ? (
                <CardList products={currentPosts} isOwner={isOwner} />
            ) : (
                currentPosts && <CardList products={[currentPosts]} />
            )}

            <Pagination 
                totalPosts={statements.length}
                postsPerPage={postsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
        </div>
    );
    
}

export default Statements