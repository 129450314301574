import React, { createContext, useState } from 'react';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [selectedDeal, setSelectedDeal] = useState({
    dealtype: [],
    propertyType: [],
    selectedDealtypeIds: [],
    selectedTypeIds: [],
  });

  const [priceRange, setPriceRange] = useState({
    startPrice: '',
    endPrice: '',
  });

  const [areaRange, setAreaRange] = useState({
    startArea: '',
    endArea: '',
    rooms: [],
  });

  const [location, setLocation] = useState({
    city: [],
    district: [],
    urban: [],
    selectedCityIndexes: [],
    selectedDistrictIndexes: [],
    selectedCityIds: [],
    selectedDistrictIds: [],
    selectedUrbanIds: [],
  });

  return (
    <FilterContext.Provider
      value={{
        selectedDeal,
        setSelectedDeal,
        priceRange,
        setPriceRange,
        areaRange,
        setAreaRange,
        location,
        setLocation,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};