import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSuperAdmin, setIsSuperAdmin] = useState(false); // A state to distinguish super admin login

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      username: username,
      password: password
    };

    try {
      const apiEndpoint = isSuperAdmin ? 'https://tbilisirealtor-api.onrender.com/api/user/login-super-admin' : 'https://tbilisirealtor-api.onrender.com:5000/api/user/login-user';

      const response = await axios.post(apiEndpoint, data, { withCredentials: true });

      if (response.status === 200) {
        Cookies.set('token', response.data.token);
        navigate('/dashboard');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <form className='form-container' onSubmit={handleSubmit}>
          <label htmlFor="">Username</label>
          <input type="text" placeholder='Username' value={username} onChange={(e) => setUsername(e.target.value)} />
          <label htmlFor="">Password</label>
          <input type="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
          <div>
            <label>
              <input
                type="checkbox"
                checked={isSuperAdmin}
                onChange={() => setIsSuperAdmin(!isSuperAdmin)}
              />
              Super Admin Login
            </label>
          </div>
          <button type='submit'>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
