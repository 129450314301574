import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom'
import "./Dashboard.css"
import Cookie from "js-cookie"

import CheckLoggedIn from '../../../middlewares/CheckLoggedIn';

import AddStatement from '../../../components/DashComponents/AddStatement/AddStatement';
import Statements from '../../../components/DashComponents/Statements/Statements'
import PopularStatements from '../../../components/DashComponents/PopularStatements/PopularStatements';

import CheckSuperAdminProtected from '../../../middlewares/CheckSuperAdminProtected';

const Dashboard = () => {

  const adminNotAuth = CheckSuperAdminProtected();

  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate()

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };


  useEffect(() => {
      if (!CheckLoggedIn()) {
        navigate('/login');
      }
  }, [navigate]);


  const logoutHandler = () => {
    Cookie.remove("token");
    // Navigate to the login page after logout
    navigate('/login');
  }

  return (
      <div className="dash-wrapper">
        <div className="dash-headline">
          <h1>ადმინისტრატორის პანელი</h1>
        </div>
  
        <div className="dash-content-wrapper">
          <div className="dash-tabs">
            <span
              className={selectedTab === 0 ? 'active' : ''}
              onClick={() => handleTabClick(0)}
            >
              განცხადებები
            </span>
            <span
              className={selectedTab === 1 ? 'active' : ''}
              onClick={() => handleTabClick(1)}
            >
              განცხადების დამატება
            </span>
            
            {adminNotAuth ? 
              <></>
              :
              <span
              className={selectedTab === 2 ? 'active' : ''}
              onClick={() => handleTabClick(2)}
              >
                პოპულარული განცხადებები
              </span>
            }
            
            <span
              className={selectedTab === 3 ? 'active' : ''}
              onClick={() => logoutHandler()}
            >
              გასვლა
            </span>
          </div>
  
          <div className="dash-content">
            {selectedTab === 0 && (
              <div className="statements-content">
                <Statements />
              </div>
            )}
            {selectedTab === 1 && (
              <div className="add-statement-content">
                <AddStatement />
              </div>
            )}
            {selectedTab === 2 && (
              <div className="popular-statements-content">
                <PopularStatements />
              </div>
            )}
            {selectedTab === 3 && <h1>Content for გასვლა</h1>}
          </div>
        </div>
      </div>
  );
};


export default Dashboard