import React from 'react'
import "./Companies.css"

import Ban1 from "../../../assets/prologis.png"
import Ban2 from "../../../assets/tower.png"
import Ban3 from "../../../assets/equinix.png"
import Ban4 from "../../../assets/realty.png"


const Companies = () => {
  return (
    <section className="c-wrapper">
        <div className="paddings innerWidth flexCenter c-container">
            <img src={Ban1} alt="" />
            <img src={Ban2} alt="" />
            <img src={Ban3} alt="" />
            <img src={Ban4} alt="" />
        </div>
    </section>
  )
}

export default Companies