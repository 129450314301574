import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import './Value.css';
import Pic from '../../../assets/value.jpg';
import data from '../../../utils/accordion';

const Value = () => {

    const [className, setClassName] = useState(null);



  return (
    <section id="values-component" className="v-wrapper">
      <div className="paddings innerWidth flexCenter v-container">
        {/* Left Side */}
        <div className="v-left">
          <div className="image-container">
            <img src={Pic} alt="" />
          </div>
        </div>

        {/* Right Side */}
        <div className="flexColStart v-right">
          <span className="orangeText">ჩვენი ფასეულობა</span>
          <span className="primaryText">რომელსაც თქვენ გაძლევთ</span>
          <span className="secondaryText">
            კაროჩე თესლაძუა სააგენტო ვართ <br />
            მოდი და ნახე შენი თვალით ბრატუხია 
          </span>

          <Accordion
            className="accordion"
            allowMultipleExpanded={false}
            preExpanded={[0]}
          >
            {data.map((item, i) => {
              const handleAccordionChange = (expanded) => {
                setClassName(expanded ? 'expanded' : 'collapsed');
              };

              return (
                <AccordionItem
                  className={`accordionItem ${className}`}
                  key={i}
                  uuid={i}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="flexCenter accordionButton">
                      <AccordionItemState>
                        {({ expanded }) => {
                          handleAccordionChange(expanded);
                          return (
                            <div className="flexCenter icon">{item.icon}</div>
                          );
                        }}
                      </AccordionItemState>
                      <span className="primaryText">{item.heading}</span>
                      <div className="flexCenter icon">
                        <MdOutlineArrowDropDown size={20} />
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel>
                    <p className="secondaryText">{item.detail}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default Value;