import React from 'react'
import "./Hero.css"

import {HiLocationMarker} from 'react-icons/hi'
import CountUp from 'react-countup'
import HeroImg from "../../../assets/realestate.jpg"

import {motion} from 'framer-motion'

const Hero = () => {
  return (
    <section className="hero-wrapper">

        <div className="paddings innerWidth flexCenter hero-container">

            {/* left side */}
            <div className="flexColStart hero-left">
                
                <div className="hero-title">
                    <div className="orange-circle"></div>
                    <motion.h1
                    initial={{y: '2rem', opcaity: 0}}
                    animate={{y:0, opacity:1}}
                    transition ={{
                        duration: 3,
                        type: 'spring'
                    }}>
                        იპოვე <br />
                        შესაფერისი <br />
                        სახლი <br />
                    </motion.h1>
                </div>

                <div className="flexColStart hero-des">

                    <span className='secondaryText'>
                        თქვენთვის შესაფერისი უძრავი ქონების დიდი არჩევანი.
                    </span>
                    <span className='secondaryText'>
                        დაივიწყეთ საცხოვრებლის ძებნისას წარმოქმნილი დაბრკოლებები.
                    </span>

                </div>

                <div className="flexCenter search-button">
                    <a href='/products'>დაიწყეთ ძებნა</a>
                </div>

                <div className="flexCenter stats">
                    
                    <div className="flexColCenter stat">

                        <span>
                            <CountUp start={8800} end={9000}/>  
                            <span>+</span>
                        </span>
                        <span className='secondaryText'>პრემიუმ პროდუქტი</span>

                    </div>

                    <div className="flexColCenter stat">

                        <span>
                            <CountUp start={1950} end={2000} duration={4}/>  
                            <span>+</span>
                        </span>
                        <span className='secondaryText'>ბედნიერი კლიენტი</span>

                    </div>

                    <div className="flexColCenter stat">

                        <span>
                            <CountUp end={208}/>  
                            <span>+</span>
                        </span>
                        <span className='secondaryText'>ექსკლუზივი</span>

                    </div>
                </div>


            </div>

            {/* right side */}
            <div className="flexCenter hero-right">
                <motion.div 
                initial={{x: "7rem", opacity: 0}}
                animate={{x:0, opacity:1}}
                transition={{
                    duration: 3,
                    type: "spring"
                }}
                className="image-container">
                    <img src={HeroImg} alt="" />
                </motion.div>
            </div>

        </div>

    </section>
  )
}

export default Hero