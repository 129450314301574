import React from 'react'

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function ParticlesPage({theme}) {
    const particlesInit = async (main) => {
      console.log(main);

      await loadFull(main);
    };

    const isMobileDevice = () => {
        return window.innerWidth <= 768; 
    };

    const isDarkMode = () => {
        return theme === 'dark';
        
    }

    const particlesKey = theme;
  
    return (
      <div className="particle-background">

  
        <Particles
            key={particlesKey}
            id="tsparticles"
            init={particlesInit}
  
            options={{
            "fpsLimit" : 60,
            "fullScreen": {
                "enable": true,
                "zIndex": -1
            },
            "particles": {
                "number": {
                    "value": isMobileDevice() ? 90 : 250,
                    "density": {
                        "enable": false,
                        "value_area": 800
                    }
                },
                "color": {
                    "value": isDarkMode() ? '#ffffff' : '#000000'
                },
                "shape": {
                    "type": "circle",
                    "options": {
                        "sides": 2
                    }
                },
                "opacity": {
                    "value": 1,
                    "random": false,
                    "anim": {
                        "enable": false,
                        "speed": 1,
                        "opacity_min": 0.1,
                        "sync": false
                    }
                },
                "size": {
                    "value": 1,
                    "random": false,
                    "anim": {
                        "enable": false,
                        "speed": 40,
                        "size_min": 0.1,
                        "sync": false
                    }
                },
                "rotate": {
                    "value": 0,
                    "random": true,
                    "direction": "clockwise",
                    "animation": {
                        "enable": true,
                        "speed": 5,
                        "sync": false
                    }
                },
                "line_linked": {
                    "enable": true,
                    "distance": isMobileDevice()? 70 : 110,
                    "color": isDarkMode() ? '#ffffff' : '#000000',
                    "opacity": 0.3,
                    "width": 1
                },
                "move": {
                    "enable": true,
                    "speed": 1,
                    "direction": "none",
                    "random": false,
                    "straight": false,
                    "out_mode": "out",
                    "attract": {
                        "enable": false,
                        "rotateX": 600,
                        "rotateY": 1200
                    }
                }
            },
            "interactivity": {
                "events": {
                    "onhover": {
                        "enable": true,
                        "mode": ["grab"]
                    },
                    "onclick": {
                        "enable": true,
                        "mode": "push"
                    },
                    "resize": true
                },
                "modes": {
                    "grab": {
                        "distance": 100,
                        "line_linked": {
                            "opacity": 1
                        }
                    },
                    "bubble": {
                        "distance": 400,
                        "size": 40,
                        "duration": 2,
                        "opacity": 8,
                        "speed": 3
                    },
                    "repulse": {
                        "distance": 200
                    },
                    "push": {
                        "particles_nb": 2
                    },
                    "remove": {
                        "particles_nb": 2
                    }
                }
            },
            
            "retina_detect": true,
            "background": {
                "color": isDarkMode() ? '#000000' : '#ffffff',
                "image": "",
                "position": "50% 50%",
                "repeat": "no-repeat",
                "size": "cover"
            }
        }}
      />
      </div>
    );
  }

export default ParticlesPage
