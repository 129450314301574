import React, { useState } from 'react';
import './RightPanel.css';
import Carousel from './carousel/Carousel';

const RightPanel = ({ images }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);

  const openModal = (index) => {
    setModalIsOpen(true);
    setModalImageIndex(index);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleImageClick = (index) => {
    openModal(index);
  };

  const renderImage = (index) => {
    return (
      <img
        src={images[index]}
        alt=""
        onClick={() => handleImageClick(index)}
      />
    );
  };

  const renderCarouselItem = (index) => {
    const upperImage = images[index] ? renderImage(index) : null;
    const midLeftImage = images[index + 1] ? renderImage(index + 1) : null;
    const midRightImage = images[index + 2] ? renderImage(index + 2) : null;
    const lowerImage = images[index + 3] ? renderImage(index + 3) : null;

    return (
      <div className="rp-container" key={index}>
        <div className="upper-part">{upperImage}</div>
        <div className="mid-part">
          <div className="half-part">{midLeftImage}</div>
          <div className="half-part">{midRightImage}</div>
        </div>
        <div className="lower-part">{lowerImage}</div>
      </div>
    );
  };

  const renderCarousel = () => {
    const carouselItems = [];
    const numImages = images.length;
    for (let i = 0; i < numImages; i += 4) {
      const endIndex = Math.min(i + 4, numImages);
      carouselItems.push(renderCarouselItem(i, endIndex));
    }
    return carouselItems;
  };
  console.log(images)
  return (
    <div className="right-panel">
      <div className="rp-wrapper">
        {renderCarousel()}
        {modalIsOpen && (
          <div className={`modal-overlay ${modalIsOpen ? 'show' : ''}`} onClick={closeModal}>

            <Carousel images={images} startingIndex={modalImageIndex} onClose={closeModal} />
            
          </div>
        )}
      </div>
    </div>
  );
};

export default RightPanel;
