import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const CheckSuperAdminProtected = () => {
  const token = Cookies.get("token");

  const [adminNotAuth, setAdminNotAuth] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://tbilisirealtor-api.onrender.com/api/user/super-admin-protectd",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAdminNotAuth(false);
      } catch (error) {
        console.log("nope");
        if (error.response) {
          const { status } = error.response;
          if (status === 401) {
            // token expired, log the user out
          } else {
            // handle other errors
            throw error;
          }
        } else {
          // handle other errors
          throw error;  
        }
      }
    };
    fetchData();
  }, []);

  return adminNotAuth;
};

export default CheckSuperAdminProtected;
