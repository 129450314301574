import React, { useState, useEffect, useRef } from 'react';
import './PriceDrop.css';

const PriceDrop = ({ priceRange, setPriceRange }) => {
  const [isActive, setIsActive] = useState(false);


  const dropdownRef = useRef(null);




  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);














  const handleSave = () => {
    setPriceRange({
      ...priceRange,
      startPrice: priceRange.startPrice,
      endPrice: priceRange.endPrice
    });
    setIsActive(false);
  };


  function renderspan(){
    if(priceRange.startPrice && priceRange.endPrice){
      return(
        <span>
          {priceRange.startPrice + " $ - " + priceRange.endPrice + " $"}
        </span>
      )
    }
    else if (priceRange.startPrice){
      return(
        <span>
          {priceRange.startPrice + "$ +"}
        </span>
      )
    }
    else if (priceRange.endPrice){
      return(
        <span>
          {"0 $ - " +priceRange.endPrice + " $"}
        </span>
      )
    }
    else{
      return <span>ფასი</span>
    }

  }



  return (
    <div className="pricedrop">
      <div className="dropdown-btn" onClick={() => setIsActive(!isActive)}>
        <span>{renderspan()}</span>
      </div>
      {isActive && (
        <div className="dropdown-content" ref={dropdownRef}>
          <p className="head-text">მიუთითეთ ფასი</p>
          <div className="top">
            <div className="left">
              <input
                type="text"
                className="price-input"
                placeholder="დან"
                value={priceRange.startPrice}
                onChange={(e) =>
                  setPriceRange({
                    ...priceRange,
                    startPrice: e.target.value
                  })
                }
              />
            </div>
            <div className="right">
              <input
                type="text"
                className="price-input"
                placeholder="მდე"
                value={priceRange.endPrice}
                onChange={(e) =>
                  setPriceRange({
                    ...priceRange,
                    endPrice: e.target.value
                  })
                }
              />
            </div>
          </div>
          <div className="hor-line"></div>
          <div className="bottom">
            <button className="dropbutton" onClick={handleSave}>
              შენახვა
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PriceDrop;