import './App.css';
import { createContext, useState, useEffect } from 'react';
import {Route, Routes} from 'react-router-dom'

//Landing Page imports
import Header from "./pages/LandingPage/Header/Header"
import Hero from "./pages/LandingPage/Hero/Hero"
import Companies from './pages/LandingPage/Companies/Companies';
import Residencies from './pages/LandingPage/Residencies/Residencies';
import Value from './pages/LandingPage/Value/Value';
import Contact from './pages/LandingPage/Contact/Contact';
import GetStarted from './pages/LandingPage/GetStarted/GetStarted';
import Footer from './pages/LandingPage/Footer/Footer';

//Listing Page imports
import ListingPage from "./pages/ListingPage/Listing"

//Detailed Listing Page Imports
import DetailedListing from "./pages/DetailedListing/DetailedListing"

//Dashboard Page imports 
import Login from "./pages/DashboardPage/Login/Login"
import Dashboard from './pages/DashboardPage/Dashboard/Dashboard';


import ParticlesPage from './components/Particles/ParticlesPage';

import { FilterProvider } from './components/SearchBar/FilterContext';


export const ThemeContext = createContext(null)


function App() {

	const [theme, setTheme] = useState(() => {
		const initialTheme = localStorage.getItem("theme");
		return initialTheme ? initialTheme : "light";
	});

	function getThemeFromLocalStorage() {
		const savedTheme = localStorage.getItem("theme");
		if (savedTheme) {
			setTheme(savedTheme);
		}
	}


	function toggleTheme() {
		setTheme((prevTheme) => {
			const newTheme = prevTheme === "light" ? "dark" : "light";
			localStorage.setItem("theme", newTheme);
			return newTheme;
		});
	}


	useEffect(() => {
		getThemeFromLocalStorage();
	}, [theme]);


  return (

      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <FilterProvider>
          <div className="App" id={theme}>
            <Routes>

              <Route path="/" element={
                <>
                  <div className='hero'>
                    <div className='white-gradient' />
                    <Header fontcolor={'white'} toggleTheme={toggleTheme} theme={theme}/>
                    <Hero />
                  </div>

                  <Companies />
                  <Residencies />
                  <Value />
                  <Contact />
                  <GetStarted />
                  <Footer />
                </>
              }
              />



              <Route path="/products" element={
                <>
                  <ParticlesPage theme={theme}/>
                  <Header 
                    toggleTheme={toggleTheme} 
                    theme={theme}
                  />
                  <ListingPage />
                </>
              }
              />

              <Route path="/ad/:id" element={
                <>
                  <ParticlesPage theme={theme}/>
                  <Header 
                    toggleTheme={toggleTheme} 
                    theme={theme}
                  />
                  <DetailedListing theme={theme}/>
                </>
              }
              />

              


              <Route path="/login" element={
                <Login />
                }
              />

              <Route path="/dashboard" element={
                <Dashboard />
                }
              />

              <Route path="/sendinfo" element={
                <Dashboard />
                }
              />


              {/* 
              <Route path="/edit/:id" element={
                <>
                  <Header color={"#FFFFFF"} fontcolor={'grey'} logo={LogoDark}/>
                  <EditStatement />
                </>
              }
              />
              */}



            </Routes>
          </div>
        </FilterProvider>
      </ThemeContext.Provider>
    

  );
}

export default App;
