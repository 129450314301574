import React, { useState } from 'react';
import './Card.css';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStairs } from '@fortawesome/free-solid-svg-icons';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { faBed } from '@fortawesome/free-solid-svg-icons';
import { faTree } from '@fortawesome/free-solid-svg-icons';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const Card = ({
  images,
  price,
  area,
  gardenArea,
  rooms,
  bedrooms,
  floorEx,
  floorAll,
  type,
  dealType,
  district,
  exLocation,
  city,
  id,
  date,
  isOwner
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const formattedDate = new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });

  return (
    <div className="m-card">
      <div className="img-wrapper">
        <img src={images[currentImageIndex]} alt="home" />
        <div className="sw-buttons">
          <button onClick={handlePreviousImage}>&lt;</button>
          <button onClick={handleNextImage}>&gt;</button>
        </div>
      </div>

    
      <Link to={`/ad/${id}`} className="details-wrapper">
        <div className="prText">
          {dealType + ' ' + type + ' ' + district + 'ში'}
        </div>
        
        <span className="secondaryText">{exLocation}</span>
        <span className="secondaryText m-price">
          <span className='priceText'>{price}</span>
          <span style={{ color: 'orange' }}>$ </span>
          <span className="secondaryText m-area">{area + ' მ²'}</span>
        </span>
        {type !== 'მიწის ნაკვეთი' && type !== 'სახლი' ? (
          <>
            <span className="secondaryText m-rooms">
              <i>
                <FontAwesomeIcon icon={faDoorOpen} />
              </i>
              {rooms + ' ოთახი'}
            </span>
            <span className="secondaryText m-bedrooms">
              <i>
                <FontAwesomeIcon icon={faBed} />
              </i>
              {bedrooms + ' საძინებელი'}
            </span>
            <span className="secondaryText m-floors">
              <i>
                <FontAwesomeIcon icon={faStairs} />
              </i>
              {floorEx + '/' + floorAll}
            </span>
          </>
        ) : (
          ''
        )}

        {type === 'სახლი' ? (
          <>
            <span className="secondaryText m-rooms">
              <i>
                <FontAwesomeIcon icon={faDoorOpen} />
              </i>
              {rooms + ' ოთახი'}
            </span>
            <span className="secondaryText m-bedrooms">
              <i>
                <FontAwesomeIcon icon={faBed} />
              </i>
              {bedrooms + ' საძინებელი'}
            </span>
            <span className="secondaryText m-gardenarea">
              <i>
                <FontAwesomeIcon icon={faTree} />
              </i>
              {gardenArea + ' მ² ეზოს ფართი'}
            </span>
            <span className="secondaryText m-floors">
              <i>
                <FontAwesomeIcon icon={faStairs} />
              </i>
              {floorAll}
            </span>
          </>
        ) : (
          ''
        )}

        
      </Link>


      
      <Link to={`/ad/${id}`} className="id-wrapper">
        <span className="prText">{'ID: ' + id}</span>
        <span className="secondaryText m-price">{formattedDate}</span>
        {isOwner? 
        <>
          <a className="edit" href={`/edit/${id}`}>
            <FontAwesomeIcon icon={faPencil} />
          </a>
          <a className="edit" href={`/`}>
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </>
        :
        <></>}
      </Link>
    </div>
  );
};

export default Card;
