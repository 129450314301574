import React from 'react'
import "../LeftPanel.css"


const Officeview = ({mainData, logos, expanded, setExpanded, showButton, setShowButton, handleToggleExpand }) => {
     

    const {
        headline,
        spcAddress,
        area,
        price,
        rooms,
        bedrooms,
        bathrooms,
        buildingCondition,
        officeType,
        projectType,
        amenitiesObj,
        floorEx,
        floorAll,
        description
    } = mainData;
      
    const {
        AreaLogo,
        Door,
        Bed,
        BathTub,
        Architecture,
        Construction,
        Design,
        Balcony,
        FireLogo,
        Garage,
        HotWater,
        Gas,
        Internet,
        Elevator,
        Furniture,
        Tv,
        AC,
        Gym,
        Stairs
    } = logos;
      
      
      
      
      
      
      




    return (
        <div className="lp-wrapper">


            <div className="address">
                <p className="main-address">{headline}</p>
                <p className="specific-address">{spcAddress}</p>
            </div>
            <div className="area-wrapper">

                <div className="area-item">
                    <div className="img-container">
                        <img src={AreaLogo} alt="" />
                    </div>
                    <span>- {area}მ²</span>
                </div>

                <div className="area-item">
                    <div className="img-container">
                        <img src={Stairs} alt="" />
                    </div>
                    <span>{floorEx}/{floorAll}</span>
                </div>

            </div>
            <div className="price">
                ${price}<span style={{color: 'grey', fontSize: '12px'}}>/თვე</span>
            </div>

            
            <div className="i-stats">
                <div className="icon-cont">
                    <div className="img-cont">
                        <img src={Door} alt="" />
                    </div>
                    <p>{rooms} ოთახი</p>
                </div>
                <div className="icon-cont">
                    <div className="img-cont">
                        <img src={Bed} alt="" />
                    </div>
                    <p>{bedrooms} საძინებელი</p>
                </div>
                <div className="icon-cont">
                    <div className="img-cont">
                        <img src={BathTub} alt="" />
                    </div>
                    <p>{bathrooms} სველი წერტილი</p>
                </div>
                
            </div>


            <div
                id="desc"
                className={`desc ${expanded ? 'expanded' : ''}`}
            >
                <p>{description}</p>
            </div>
            {showButton && (
                <p
                className="read-more"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={handleToggleExpand}
                >
                {expanded ? 'Read less' : 'Read more'}
                </p>
            )}


            <div className="lower-stats">

                <div className="down-row">

                    <h2>სივრცე</h2>

                    <div className="down-row-item">
                        <div className="img-container">
                            <img src={Architecture} alt="" />
                        </div>
                        <p>{buildingCondition}</p>
                    </div>

                    <div className="down-row-item">
                        <div className="img-container">
                            <img src={Construction} alt="" />
                        </div>
                        <p>{officeType}</p>
                    </div>

                    <div className="down-row-item">
                        <div className="img-container">
                            <img src={Design} alt="" />
                        </div>
                        <p>{projectType}</p>
                    </div>
                    <div className={`down-row-item ${amenitiesObj.balcony ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Balcony} alt="" />
                        </div>
                        <p>აივანი</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.veranda ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Balcony} alt="" />
                        </div>
                        <p>ვერანდა</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.centralHeating ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={FireLogo} alt="" />
                        </div>
                        <p>ცენტრალური გათბობა</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.parking ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Garage} alt="" />
                        </div>
                        <p>პარკინგი</p>
                    </div>

                </div>

                <div className="down-row">

                    <h2>კეთილმოწყობა</h2>

                    <div className={`down-row-item ${amenitiesObj.hotWater ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={HotWater} alt="" />
                        </div>
                        <p>ცხელი წყალი</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.gas ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Gas} alt="" />
                        </div>
                        <p>ბუნებრივი აირი</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.internet ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Internet} alt="" />
                        </div>
                        <p>ინტერნეტი</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.furniture ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Furniture} alt="" />
                        </div>
                        <p>ავეჯი</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.elevator ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Elevator} alt="" />
                        </div>
                        <p>ლიფტი</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.tv ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Tv} alt="" />
                        </div>
                        <p>ტელევიზორი</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.ac ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={AC} alt="" />
                        </div>
                        <p>კონდიციონერი</p>
                    </div>

                    <div className={`down-row-item ${amenitiesObj.gym ? '' : 'negative'}`}>
                        <div className="img-container">
                            <img src={Gym} alt="" />
                        </div>
                        <p>სპორტდარბაზი</p>
                    </div>

                </div>

            </div>
            
        </div>
    )
}

export default Officeview