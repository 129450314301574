import React, { useState, useEffect } from 'react';

const OfficeStatement = ({ onSelectedData  }) => {
  const [selectedData, setSelectedData] = useState({});
  const [isDropdownOpen, setDropdownOpen] = useState({});
  const [prevSelectedData, setPrevSelectedData] = useState({});





  useEffect(() => {
    if (selectedData !== prevSelectedData) {
      onSelectedData(selectedData);
      setPrevSelectedData(selectedData);
    }
  }, [selectedData, onSelectedData, prevSelectedData]);


  const dropdownData = [
    {
      id: 'officeType',
      name: 'ოფისის ტიპი',
      options: [
        { name: 'საოფისე' },
        { name: 'სასაწყობე' },
        { name: 'საპროდუქციო' },
        { name: 'კვების' },
        { name: 'ფარეხი' },
        { name: 'სპეციალური' },
        { name: 'უნივერსალური' },
      ],
    },
    {
      id: 'projectType',
      name: 'პროექტის ტიპი',
      options: [
        { name: 'არასტანდარტული' },
        { name: 'თუხარელი' },
        { name: 'იტალიური ეზო' },
        { name: 'ლვოვი' },
        { name: 'ქალაქური' },
        { name: 'ხრუშოვი' },
        { name: 'დუპლექსი' },
        { name: 'ტრიპლექსი' }
      ],
    },
    {
      id: 'buildingCondition',
      name: 'მდგომარეობა',
      options: [
        { name: 'ახალი რემონტი' },
        { name: 'ძველი რემონტი' },
        { name: 'სარემონტო' },
        { name: 'თეთრი კარკასი' },
        { name: 'შავი კარკასი' },
        { name: 'მწავნე კარკასი' },
      ],
    },
    // Add more dropdown data here...
  ];

  const checkboxData = [
    { id: 'სპორტდარბაზი', label: 'სპორტდარბაზი' },
    { id: 'პარკინგი', label: 'პარკინგი' },
    { id: 'აივანი', label: 'აივანი' },
    { id: 'ვერანდა', label: 'ვერანდა' },
    { id: 'ცენტრალური გათბობა', label: 'ცენტრალური გათბობა' },
    { id: 'ცხელი წყალი', label: 'ცხელი წყალი' },
    { id: 'ბუნებრივი აირი', label: 'ბუნებრივი აირი' },
    { id: 'ლიფტი', label: 'ლიფტი' },
    { id: 'ავეჯი', label: 'ავეჯი' },
    { id: 'ტელევიზორი', label: 'ტელევიზორი' },
    { id: 'კონდიციონერი', label: 'კონდიციონერი' },
    { id: 'ინტერნეტი', label: 'ინტერნეტი' }
    // Add more checkboxes here...
  ];

  const handleDropdownToggle = (id) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleOptionSelect = (dropdownId, optionName) => {
    setSelectedData((prevState) => ({
      ...prevState,
      [dropdownId]: optionName
    }));
    setDropdownOpen((prevState) => ({
      ...prevState,
      [dropdownId]: false
    }));
  };

  const handleRoomsSelect = (roomValue) => {
    setSelectedData((prevState) => ({
      ...prevState,
      rooms: roomValue
    }));
  };

  const handleBedroomsSelect = (bedroomValue) => {
    setSelectedData((prevState) => ({
      ...prevState,
      bedrooms: bedroomValue
    }));
  };

  const handleBathroomsSelect = (bathroomValue) => {
    setSelectedData((prevState) => ({
      ...prevState,
      bathrooms: bathroomValue
    }));
  };


  const handleFloorExSelect = (floorExValue) => {
    setSelectedData((prevState) => ({
      ...prevState,
      floorEx: floorExValue
    }));
  };

  const handleFloorAllSelect = (floorAllValue) => {
    setSelectedData((prevState) => ({
      ...prevState,
      floorAll: floorAllValue
    }));
  };

  const handleAreaSelect = (areaValue) => {
    setSelectedData((prevState) => ({
      ...prevState,
      area: areaValue
    }));
  };

  const handlePriceSelect = (priceValue) => {
    setSelectedData((prevState) => ({
      ...prevState,
      price: priceValue
    }));
  };

  const handleCommentSelect = (commentValue) => {
    setSelectedData((prevState) => ({
      ...prevState,
      description: commentValue
    }));
  };



  const handleCheckboxChange = (checkboxId, isChecked) => {
    setSelectedData((prevState) => {
      let updatedAmenities = [...(prevState.amenities || [])];
      if (isChecked) {
        // Add checkbox value to the amenities array
        updatedAmenities.push(checkboxId);
      } else {
        // Remove checkbox value from the amenities array
        updatedAmenities = updatedAmenities.filter((amenity) => amenity !== checkboxId);
      }
      return {
        ...prevState,
        amenities: updatedAmenities,
      };
    });
  };


  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setSelectedData((prevState) => ({
      ...prevState,
      images: files
    }));
  };



  const renderDropdowns = () => {
    return dropdownData.map((dropdown) => (
      <div key={dropdown.id} className={`${dropdown.id}-drop`}>
        <button
          className="drop-button"
          onClick={() => handleDropdownToggle(dropdown.id)}
        >
          {selectedData[dropdown.id] ? selectedData[dropdown.id] : dropdown.name}
        </button>
        {isDropdownOpen[dropdown.id] && (
          <div className={`drop-content ${isDropdownOpen[dropdown.id] ? 'show' : ''}`}>
            {dropdown.options.map((option) => (
              <span
                key={option.name}
                onClick={() => handleOptionSelect(dropdown.id, option.name)}
              >
                {option.name}
              </span>
            ))}
          </div>
        )}
      </div>
    ));
  };

  const renderRoomsCheckboxes = () => {
    const rooms = [1, 2, 3, 4, 5];
    return (
      <div className="rooms-checkboxes">
        <span className="checkbox-title">ოთახი</span>
        {rooms.map((room) => (
          <label key={room} className="checkbox-label">
            <input
              type="checkbox"
              className="main-checkbox"
              value={room}
              checked={selectedData.rooms === room}
              onChange={(e) => handleRoomsSelect(Number(e.target.value))}
            />
            <span className="custom-checkbox">{room}</span>
          </label>
        ))}
      </div>
    );
  };

  const renderBedroomsCheckboxes = () => {
    const bedrooms = [];
    for (let i = 1; i < selectedData.rooms; i++) {
      bedrooms.push(i);
    }
    return (
      <div className="bedrooms-checkboxes">
        <span className="checkbox-title">საძინებელი</span>
        {bedrooms.map((bedroom) => (
          <label key={bedroom} className="checkbox-label">
            <input
              type="checkbox"
              className="main-checkbox"
              value={bedroom}
              
              checked={selectedData.bedrooms === bedroom}
              onChange={(e) => handleBedroomsSelect(Number(e.target.value))}
            />
            <span className="custom-checkbox">{bedroom}</span>
          </label>
        ))}
      </div>
    );
  };


  const renderBathroomsCheckboxes = () => {
    const bathrooms = [1, 2, 3, 4];
    return (
      <div className="bathrooms-checkboxes">
        <span className="checkbox-title">სველი წერტილი</span>
        {bathrooms.map((bathroom) => (
          <label key={bathroom} className="checkbox-label">
            <input
              type="checkbox"
              className="main-checkbox"
              value={bathroom}
              
              checked={selectedData.bathrooms === bathroom}
              onChange={(e) => handleBathroomsSelect(Number(e.target.value))}
            />
            <span className="custom-checkbox">{bathroom}</span>
          </label>
        ))}
      </div>
    );
  };



  const renderFloorFields = () => {
    return (
      <div>

        <input
          type="number"
          className="double-input"
          value={selectedData.floorEx}
          placeholder="სართული"
          onChange={(e) => handleFloorExSelect(Number(e.target.value))}
          min="0" // Set the minimum allowed value to 0
        />

        <input
          type="number"
          className="double-input"
          value={selectedData.floorAll}
          placeholder="სართულები სულ"
          onChange={(e) => handleFloorAllSelect(Number(e.target.value))}
          min="0" // Set the minimum allowed value to 0
        />

      </div>
    );
  };

  const renderAreaPrice = () => {
    return (
      <div>

        <input
          type="number"
          className='double-input'
          value={selectedData.area}
          placeholder="ფართი"
          onChange={(e) => handleAreaSelect(Number(e.target.value))}
        />

        <input
          type="number"
          className='double-input'
          value={selectedData.price}
          placeholder="ფასი"
          onChange={(e) => handlePriceSelect(Number(e.target.value))}
        />

      </div>
    );
  };


  const renderComment = () => {
    return (
      <div>
          <textarea
            type="text"
            className='comment-wrapper'
            value={selectedData.description}
            placeholder='კომენტარი'
            onChange={(e) => handleCommentSelect(e.target.value)}
          />
      </div>
    );
  };


  const renderCheckboxes = () => {
    return checkboxData.map((checkbox) => (
      <label key={checkbox.id} className="checkbox-container">
        <input
          type="checkbox"
          checked={selectedData.amenities && selectedData.amenities.includes(checkbox.id)}
          onChange={(e) => handleCheckboxChange(checkbox.id, e.target.checked)}
        />
        <span className="checkmark"></span>
        {checkbox.label}
      </label>
    ));
  };


  const renderImageInput = () => {
    return (
      <div className="image-input">
        <input type="file" accept="image/*" multiple onChange={handleImageUpload} id="image-upload" />
        <label htmlFor="image-upload" className="image-upload-label">
          {selectedData.images && selectedData.images.length > 0 ? (
            <div className="image-preview">
              {/* Render the selected images */}
              {selectedData.images.map((image, index) => (
                <img key={index} src={URL.createObjectURL(image)} alt={`Images ${index}`} />
              ))}
            </div>
          ) : (
            <div className="no-images-selected">No images selected</div>
          )}
        </label>
      </div>
    );
  };


  return (
    <div>
      <div className="detailed-information">
        <h2>მახასიათებლები</h2>
        {renderDropdowns()}
        {renderRoomsCheckboxes()}
        {renderBedroomsCheckboxes()}
        {renderFloorFields()}
        {renderBathroomsCheckboxes()}
      </div>

      <div className="parameters-information">
        <h2>პარამეტრები და კეთილმოწყობა</h2>
        {renderCheckboxes()}
      </div>

      <div className="area-price-container">
        {renderAreaPrice()}
      </div>

      <div className="comment-container">
        {renderComment()}
      </div>

      <div className="images-container">
        {renderImageInput()}
      </div>

    </div>
  );
};

export default OfficeStatement;
