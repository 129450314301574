import React from 'react'
import './Footer.css'
import Logo from "../../../assets/logo.png"


const Footer = () => {
  return (
    <section className="f-wrapper">
        <div className="paddings innerWidth flexCenter f-container">

            {/* left side */}
            <div className="flexColStart f-left">
                <img src={Logo} alt="" width={120}/>

                <span className="secondaryText">
                    ჩვენი ხედვაა დავეხმაროთ მომხმარებელს
                    <br />
                    მათთვის საუკეთესო საცხოვრებლის პოვნაში.
                </span>
            </div>

            {/* right side */}
            <div className="flexColStart f-right">
                <span className='primaryText'>მისამართი</span>
                <span className='secondaryText'>მიცკევიჩის ქ. 29/5. თბილისი</span>

                <div className="flexCenter f-menu">
                    <span>უძრავი ქონება</span>
                    <span>სერვისები</span>
                    <span>პროდუქტი</span>
                    <span>ჩვენს შესახებ</span>
                </div>
            </div>

        </div>
    </section>
  )
}

export default Footer