import React, { useState, useEffect } from 'react';

const LandStatement = ({ onSelectedData  }) => {
  const [selectedData, setSelectedData] = useState({});
  const [isDropdownOpen, setDropdownOpen] = useState({});
  const [prevSelectedData, setPrevSelectedData] = useState({});





  useEffect(() => {
    if (selectedData !== prevSelectedData) {
      onSelectedData(selectedData);
      setPrevSelectedData(selectedData);
    }
  }, [selectedData, onSelectedData, prevSelectedData]);


  const dropdownData = [
    {
      id: 'landStatus',
      name: 'მიწის ნაკვეთის ტიპი',
      options: [
        { name: 'სასოფლო' },
        { name: 'არასასოფლო' },
        { name: 'კომერციული' },
        { name: 'სპეციალური' },
        { name: 'საინვესტიციო' }
      ],
    },

    // Add more dropdown data here...
  ];

  const checkboxData = [
    { id: 'წყალი', label: 'წყალი' },
    { id: 'ასფალტიანი გზა', label: 'ასფალტიანი გზა' },
    { id: 'ელ. ენერგია', label: 'ელ. ენერგია' },
    { id: 'კანალიზაცია', label: 'კანალიზაცია' },
    { id: 'ბუნებრივი აირი', label: 'ბუნებრივი აირი' }
    // Add more checkboxes here...
  ];

  const handleDropdownToggle = (id) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleOptionSelect = (dropdownId, optionName) => {
    setSelectedData((prevState) => ({
      ...prevState,
      [dropdownId]: optionName
    }));
    setDropdownOpen((prevState) => ({
      ...prevState,
      [dropdownId]: false
    }));
  };

  

  const handleAreaSelect = (areaValue) => {
    setSelectedData((prevState) => ({
      ...prevState,
      area: areaValue
    }));
  };

  const handlePriceSelect = (priceValue) => {
    setSelectedData((prevState) => ({
      ...prevState,
      price: priceValue
    }));
  };

  const handleCommentSelect = (commentValue) => {
    setSelectedData((prevState) => ({
      ...prevState,
      description: commentValue
    }));
  };



  const handleCheckboxChange = (checkboxId, isChecked) => {
    setSelectedData((prevState) => {
      let updatedAmenities = [...(prevState.amenities || [])];
      if (isChecked) {
        // Add checkbox value to the amenities array
        updatedAmenities.push(checkboxId);
      } else {
        // Remove checkbox value from the amenities array
        updatedAmenities = updatedAmenities.filter((amenity) => amenity !== checkboxId);
      }
      return {
        ...prevState,
        amenities: updatedAmenities,
      };
    });
  };


  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setSelectedData((prevState) => ({
      ...prevState,
      images: files
    }));
  };



  const renderDropdowns = () => {
    return dropdownData.map((dropdown) => (
      <div key={dropdown.id} className={`${dropdown.id}-drop`}>
        <button
          className="drop-button"
          onClick={() => handleDropdownToggle(dropdown.id)}
        >
          {selectedData[dropdown.id] ? selectedData[dropdown.id] : dropdown.name}
        </button>
        {isDropdownOpen[dropdown.id] && (
          <div className={`drop-content ${isDropdownOpen[dropdown.id] ? 'show' : ''}`}>
            {dropdown.options.map((option) => (
              <span
                key={option.name}
                onClick={() => handleOptionSelect(dropdown.id, option.name)}
              >
                {option.name}
              </span>
            ))}
          </div>
        )}
      </div>
    ));
  };



  const renderAreaPrice = () => {
    return (
      <div>

        <input
          type="number"
          className='double-input'
          value={selectedData.area}
          placeholder="ფართი"
          onChange={(e) => handleAreaSelect(Number(e.target.value))}
        />

        <input
          type="number"
          className='double-input'
          value={selectedData.price}
          placeholder="ფასი"
          onChange={(e) => handlePriceSelect(Number(e.target.value))}
        />

      </div>
    );
  };

  const renderComment = () => {
    return (
      <div>
          <textarea
            type="text"
            className='comment-wrapper'
            value={selectedData.description}
            placeholder='კომენტარი'
            onChange={(e) => handleCommentSelect(e.target.value)}
          />
      </div>
    );
  };

  const renderCheckboxes = () => {
    return checkboxData.map((checkbox) => (
      <label key={checkbox.id} className="checkbox-container">
        <input
          type="checkbox"
          checked={selectedData.amenities && selectedData.amenities.includes(checkbox.id)}
          onChange={(e) => handleCheckboxChange(checkbox.id, e.target.checked)}
        />
        <span className="checkmark"></span>
        {checkbox.label}
      </label>
    ));
  };

  const renderImageInput = () => {
    return (
      <div className="image-input">
        <input type="file" accept="image/*" multiple onChange={handleImageUpload} id="image-upload" />
        <label htmlFor="image-upload" className="image-upload-label">
          {selectedData.images && selectedData.images.length > 0 ? (
            <div className="image-preview">
              {/* Render the selected images */}
              {selectedData.images.map((image, index) => (
                <img key={index} src={URL.createObjectURL(image)} alt={`Images ${index}`} />
              ))}
            </div>
          ) : (
            <div className="no-images-selected">No images selected</div>
          )}
        </label>
      </div>
    );
  };


  return (
    <div>
      <div className="detailed-information">
        <h2>მახასიათებლები</h2>
        {renderDropdowns()}
      </div>

      <div className="parameters-information">
        <h2>პარამეტრები და კეთილმოწყობა</h2>
        {renderCheckboxes()}
      </div>

      <div className="area-price-container">
        {renderAreaPrice()}
      </div>

      <div className="comment-container">
        {renderComment()}
      </div>

      <div className="images-container">
        {renderImageInput()}
      </div>

    </div>
  );
};

export default LandStatement;
