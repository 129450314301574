import React from 'react';
import Card from "../Card/Card";
import "./CardList.css";

const CardList = ({ products, isOwner }) => {
  return (
    <div className='cl-wrapper'>
      <div className="cl-cards">
        {Array.isArray(products) ? (
          products.map((product) => (
            <Card
              key={product.ad_id}
              images={product.images}
              price={product.price}
              area={product.area}
              gardenArea={product.gardenArea}
              rooms={product.rooms}
              bedrooms={product.bedrooms}
              floorEx={product.floorEx}
              floorAll={product.floorAll}
              type={product.type.display_name}
              dealType={product.dealType.display_name}
              district={product.district.display_name}
              city={product.city.display_name}
              exLocation={product.exLocation}
              id={product.ad_id}
              date={product.createdAt}
              isOwner={isOwner}
            />
          ))
        ) : (
          <Card
            key={products.ad_id}
            images={products.images}
            price={products.price}
            area={products.area}
            gardenArea={products.gardenArea}
            rooms={products.rooms}
            bedrooms={products.bedrooms}
            floorEx={products.floorEx}
            floorAll={products.floorAll}
            type={products.type.display_name}
            dealType={products.dealType.display_name}
            district={products.district.display_name}
            city={products.city.display_name}
            exLocation={products.exLocation}
            id={products.ad_id}
            date={products.createdAt}
            isOwner={isOwner}
          />
        )}
      </div>
    </div>
  );
}

export default CardList;
