import React from 'react'

import './Contact.css'
import ContactPic from '../../../assets/contact2.jpg'

import {MdCall} from 'react-icons/md'
import {BsFillChatDotsFill} from 'react-icons/bs'


const Contact = () => {
  return (
    <section id="contacts-component" className="c-wrapper">
        <div className="paddings innerWidth flexCenter c-container">
            {/* Left Side*/}

            <div className="flexColStart c-left">
                <span className='orangeText'>ჩვენი კონტაქტები</span>
                <span className='primaryText'>ჩვენთან დაკავშირება უმარტივესია</span>
                <span className='secondaryText'>ყოველთვის მზად ვართ დაგეხმაროთ</span>
            
            
                <div className="flexColStart contactModes">
                    {/* first row */}
                    <div className="flexStart row">
                        {/* first mode */}
                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <MdCall size={25}/>
                                </div>
                                <div className="flexColStart detail">
                                    <span className='primaryText'>მობილური</span>
                                    <span className='secondaryText'>+(995) 592 30 33 30</span>
                                </div>
                            </div>
                            <div className="flexCenter button">
                            დააზვანოკეთ
                            </div>
                        </div>

                        {/* second mode */}
                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <BsFillChatDotsFill size={25}/>
                                </div>
                                <div className="flexColStart detail">
                                    <span className='primaryText'>Whatsapp</span>
                                    <span className='secondaryText'>+(995) 592 30 33 30</span>
                                </div>
                            </div>
                            <div className="flexCenter button">
                                მოგვწერეთ
                            </div>
                        </div>
                    </div>
                    {/* second row */}
                    <div className="flexStart row">
                        {/* third mode */}
                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <MdCall size={25}/>
                                </div>
                                <div className="flexColStart detail">
                                    <span className='primaryText'>მობილური 2</span>
                                    <span className='secondaryText'>+(995) 592 30 33 30</span>
                                </div>
                            </div>
                            <div className="flexCenter button">
                                დააზვანოკეთ
                            </div>
                        </div>

                        {/* fourth mode */}
                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <BsFillChatDotsFill size={25}/>
                                </div>
                                <div className="flexColStart detail">
                                    <span className='primaryText'>Viber</span>
                                    <span className='secondaryText'>+(995) 592 30 33 30</span>
                                </div>
                            </div>
                            <div className="flexCenter button">
                                მოგვწერეთ
                            </div>
                        </div>
                    </div>
                </div>
            
            
            </div>
            {/* Right Side*/}
            <div className="c-right">
                <div className="image-container">
                    <img src={ContactPic} alt="" />
                </div>
            </div>

        </div>
    </section>
  )
}

export default Contact