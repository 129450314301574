import React, { useState } from 'react'
import { Link } from 'react-scroll';
import  {Link  as Direct} from "react-router-dom"

import logo from "../../../assets/Realtor.png"

import "./Header.css"

import {BiMenuAltRight} from 'react-icons/bi'

import OutsideClickHandler from 'react-outside-click-handler'

import LightSwitch from '../../../components/LightSwitch/LightSwitch';

const Header = ({toggleTheme, theme}) => {


  const [menuOpened, setMenuOpened] = useState(false)
  const getMenuStyles = (menu) => {
    if(document.documentElement.clientWidth <= 800){
      return{right: !menuOpened && "-100%"}
    }
  }



  return (
    <section className="h-wrapper" >
        <div className="flexCenter paddings innerWidth h-container" >

            <Direct to="/" className='logo-link'>
              <img src={logo} alt="logo"/>
            </Direct>

            <OutsideClickHandler
            onOutsideClick={() => {
              setMenuOpened(false)
            }}>

              <div className="flexCenter h-menu"
              style={getMenuStyles(menuOpened)}>
                
                <Link 
                  to="residence-component"
                  smooth={true}
                  duration={10}
                  className='scroll-button'
                >
                  საცხოვრებელი
                </Link>

                <Link 
                  to="values-component"
                  smooth={true}
                  duration={10}
                  className='scroll-button'
                >
                  ჩვენი ფასეულობა
                </Link>

                <Link 
                  to="contacts-component"
                  smooth={true}
                  duration={10}
                  className='scroll-button'
                >
                  კონტაქტი
                </Link>
                  
                <div className="info-button">
                  მოგვაწოდეთ ინფორმაცია
                </div>

                <LightSwitch onChange={toggleTheme} checked={theme === 'dark'}/>

              </div>
            </OutsideClickHandler>

            <div className="menu-icon" onClick={() => setMenuOpened((prev) => !prev)}>
              <BiMenuAltRight size={30} />
            </div>

        </div>

    </section>
  )
}

export default Header